import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgChevronLeftSm({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M15 19a1 1 0 01-.71-.29l-6-6a1 1 0 010-1.41l6-6a1 1 0 011.41 1.41L10.41 12l5.29 5.29A1 1 0 0115 19z"
        fill={fill}
      />
    </Svg>
  );
}

SvgChevronLeftSm.defaultProps = {
  fill: colors.n7,
  size: 24
};

SvgChevronLeftSm.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgChevronLeftSm);
