import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TextInput, TouchableOpacity } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// app state
import Context from '../context';

function MenuItemInput({ text, noDivider, onPress }) {
  // app state
  const { zip, setZip } = React.useContext(Context);

  const borderBottomWidth = noDivider ? 0 : 1;

  return (
    <TouchableOpacity
      activeOpacity={gStyle.activeOpacity}
      onPress={onPress}
      style={[styles.container, { borderBottomWidth }]}
    >
      <Text style={styles.label}>{text}</Text>

      <TextInput
        onChangeText={setZip}
        selectionColor={colors.b4}
        style={styles.input}
        value={zip}
      />
    </TouchableOpacity>
  );
}

MenuItemInput.defaultProps = {
  noDivider: false,
  onPress: () => null
};

MenuItemInput.propTypes = {
  // required
  text: PropTypes.string.isRequired,

  // optional
  noDivider: PropTypes.bool,
  onPress: PropTypes.func
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flexRowAlignCenter,
    ...gStyle.mH2,
    ...gStyle.pV2,
    borderBottomColor: colors.n2,
    justifyContent: 'space-between'
  },
  label: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  input: {
    ...gStyle.pH2,
    backgroundColor: colors.n1,
    borderColor: colors.n5,
    borderRadius: 8,
    borderWidth: 1,
    color: colors.n7,
    fontFamily: fonts.msRegular,
    height: 40
  }
});

export default React.memo(MenuItemInput);
