import colors from './colors';
import device from './device';
import fonts from './fonts';

// define space grid
// /////////////////////////////////////////////////////////////////////////////
// some people use 8pt grid, some 5pt
// this is setting one place then done
const spaceGrid = 8;

const spaceHalf = Math.ceil(spaceGrid / 2);
const space1 = spaceGrid;
const space2 = spaceGrid * 2;
const space3 = spaceGrid * 3;
const space4 = spaceGrid * 4;
const space6 = spaceGrid * 6;
const space8 = spaceGrid * 8;
const space12 = spaceGrid * 12;
const space16 = spaceGrid * 16;

export default {
  activeOpacity: 0.7,

  // containers
  // ///////////////////////////////////////////////////////////////////////////
  containerBg: {
    backgroundColor: colors.n0,
    flex: 1
  },

  // drop shadow (iOS only)
  // ///////////////////////////////////////////////////////////////////////////
  dropshadow: {
    shadowColor: colors.n7,
    shadowOffset: {
      height: 1,
      width: 1
    },
    shadowOpacity: 0.3,
    shadowRadius: 8
  },

  // buttons
  // ///////////////////////////////////////////////////////////////////////////
  headingIcon: {
    alignItems: 'center',
    backgroundColor: colors.n1,
    borderRadius: 20,
    height: 40,
    justifyContent: 'center',
    width: 40
  },

  // flex
  // ///////////////////////////////////////////////////////////////////////////
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flex3: { flex: 3 },
  flex4: { flex: 4 },
  flex5: { flex: 5 },

  flexAlignCenter: {
    alignItems: 'center'
  },
  flexCenter: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexRow: {
    flexDirection: 'row'
  },
  flexRowAlignCenter: {
    alignItems: 'center',
    flexDirection: 'row'
  },
  flexRowCenter: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  flexRowEnd: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flexRowSpace: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  // margin
  // ///////////////////////////////////////////////////////////////////////////
  mBHalf: { marginBottom: spaceHalf },
  mB1: { marginBottom: space1 },
  mB2: { marginBottom: space2 },
  mB3: { marginBottom: space3 },
  mB4: { marginBottom: space4 },
  mH1: { marginHorizontal: space1 },
  mH2: { marginHorizontal: space2 },
  mH3: { marginHorizontal: space3 },
  mLHalf: { marginLeft: spaceHalf },
  mL1: { marginLeft: space1 },
  mL2: { marginLeft: space2 },
  mRHalf: { marginRight: spaceHalf },
  mR1: { marginRight: space1 },
  mR2: { marginRight: space2 },
  mT1: { marginTop: space1 },
  mT2: { marginTop: space2 },
  mT3: { marginTop: space3 },
  mT4: { marginTop: space4 },
  mT8: { marginTop: space8 },
  mTHalf: { marginTop: spaceHalf },
  mV1: { marginVertical: space1 },
  mV2: { marginVertical: space2 },

  // padding
  // ///////////////////////////////////////////////////////////////////////////
  pHalf: { padding: spaceHalf },
  p1: { padding: space1 },
  p2: { padding: space2 },
  p3: { padding: space3 },
  pB1: { paddingBottom: space1 },
  pB2: { paddingBottom: space2 },
  pB3: { paddingBottom: space3 },
  pH1: { paddingHorizontal: space1 },
  pH2: { paddingHorizontal: space2 },
  pH3: { paddingHorizontal: space3 },
  pL1: { paddingLeft: space1 },
  pL2: { paddingLeft: space2 },
  pL3: { paddingLeft: space3 },
  pR1: { paddingRight: space1 },
  pR2: { paddingRight: space2 },
  pR3: { paddingRight: space3 },
  pR4: { paddingRight: space4 },
  pT1: { paddingTop: space1 },
  pT2: { paddingTop: space2 },
  pT3: { paddingTop: space3 },
  pT4: { paddingTop: space4 },
  pT6: { paddingTop: space6 },
  pTHalf: { paddingTop: spaceHalf },
  pV1: { paddingVertical: space1 },
  pV2: { paddingVertical: space2 },

  // spacers
  // ///////////////////////////////////////////////////////////////////////////
  spacerHalf: { height: spaceHalf },
  spacer1: { height: space1 },
  spacer2: { height: space2 },
  spacer3: { height: space3 },
  spacer4: { height: space4 },
  spacer6: { height: space6 },
  spacer8: { height: space8 },
  spacer12: { height: space12 },
  spacer16: { height: space16 },
  spacer1W: { width: space1 },
  spacer2W: { width: space2 },

  // navbar header
  // ///////////////////////////////////////////////////////////////////////////
  navHeaderContainerStyle: {
    backgroundColor: colors.n0,
    borderBottomColor: colors.n2,
    ...(device.web ? { height: 72 } : {})
    // next line removes shadow from navbar header
    // shadowColor: 'transparent'
  },
  navHeaderTitleStyle: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 16
  }
};
