import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { fonts, gStyle } from '../constants';

// screens
import SearchScreen from '../screens/Search';
import SearchResultScreen from '../screens/SearchResult';
import ViewItemScreen from '../screens/ViewItem';

// icons
import { SvgChevronLeft, SvgThreeDotVert } from '../icons';

// app state
import Context from '../context';

const Stack = createStackNavigator();

function StackSearch() {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  // translations
  const itemText = localeStrings('item', locale);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Search"
        component={SearchScreen}
        options={{ header: () => null }}
      />

      <Stack.Screen
        name="SearchResult"
        component={SearchResultScreen}
        options={{
          animationEnabled: false,
          gestureEnabled: true,
          header: () => null
        }}
      />

      <Stack.Screen
        name="ViewItem"
        component={ViewItemScreen}
        options={({ navigation }) => ({
          headerLeft: () => (
            <TouchableOpacity
              activeOpacity={gStyle.activeOpacity}
              onPress={() => navigation.goBack(null)}
              style={[gStyle.headingIcon, gStyle.mL1]}
            >
              <SvgChevronLeft size={20} />
            </TouchableOpacity>
          ),
          headerRight: () => (
            <View style={[gStyle.headingIcon, gStyle.mR1]}>
              <SvgThreeDotVert size={20} />
            </View>
          ),
          headerStyle: gStyle.navHeaderContainerStyle,
          headerTitleAlign: 'center',
          headerTitleStyle: { fontFamily: fonts.msBold },
          title: itemText
        })}
      />
    </Stack.Navigator>
  );
}

export default StackSearch;
