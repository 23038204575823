import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgArrowTopLeftSm({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M17.71 16.29L10.41 9H16a1 1 0 100-2H8a1 1 0 00-1 1v8a1 1 0 102 0v-5.59l7.29 7.29a1 1 0 001.41-1.41h.01z"
        fill={fill}
      />
    </Svg>
  );
}

SvgArrowTopLeftSm.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgArrowTopLeftSm.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgArrowTopLeftSm);
