import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { Calendar } from 'react-native-calendars';
import * as Haptics from 'expo-haptics';
import { colors, device, fonts, gStyle, images } from '../constants';

// components
import Button from '../components/Button';
import CardItemOverview from '../components/CardItemOverview';
import Divider from '../components/Divider';

// icons
import { SvgChevronLeft, SvgChevronRight } from '../icons';

// data
import tireShops from '../data/tireShops.json';

// app state
import Context from '../context';

const { Light } = Haptics.ImpactFeedbackStyle;

function TireInstallSchedule({ navigation, route }) {
  // app state
  const cntx = React.useContext(Context);
  const { deliveryStart, deliveryEndShort, locationKey } = cntx;
  const { selectedDay, setSelectedDay, todaysDate, todaysDateStart } = cntx;

  // local state
  const [selectedRange, setSelectedRange] = React.useState('morning');
  const [selectedObj, setSelectedObj] = React.useState({});

  const { item, itemId } = route.params;

  const selectedInstaller = tireShops[locationKey];

  React.useEffect(() => {
    // mount
    const obj = {
      [todaysDate]: {
        customStyles: {
          container: {
            backgroundColor: colors.n0,
            borderColor: colors.n7,
            borderWidth: 1
          },
          text: {
            color: colors.n7
          }
        }
      },
      [todaysDateStart]: {
        customStyles: {
          text: {
            color: colors.n7
          }
        }
      }
    };

    if (selectedDay !== null) {
      obj[selectedDay] = { selected: true };
    }

    setSelectedObj(obj);

    return () => {
      // unmount
    };
  }, [selectedDay]);

  return (
    <React.Fragment>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
        <CardItemOverview item={item} selected={selectedInstaller} />

        <View style={gStyle.spacer1} />

        <View style={gStyle.pH2}>
          <Text style={styles.heading}>Select drop-off day and time</Text>

          <View style={gStyle.spacer1} />

          <Text style={styles.textMuted}>
            Installation is available after{' '}
            <Text style={styles.textBold}>{deliveryEndShort}</Text>. Selecting
            an Evening drop-off time will require leaving your vehicle
            overnight.
          </Text>

          <View style={gStyle.spacer2} />

          <Calendar
            minDate={deliveryStart}
            onDayPress={(d) => setSelectedDay(d.dateString)}
            enableSwipeMonths={false}
            markingType="custom"
            markedDates={selectedObj}
            renderArrow={(direction) => {
              const Icon =
                direction === 'left' ? SvgChevronLeft : SvgChevronRight;

              return <Icon size={16} />;
            }}
            theme={{
              selectedDayBackgroundColor: colors.n7,
              selectedDayTextColor: colors.n0,
              dayTextColor: colors.n7,
              textDisabledColor: colors.n3,
              arrowColor: colors.n7,
              monthTextColor: colors.n7,
              textDayFontFamily: fonts.msRegular,
              textMonthFontFamily: fonts.msBold,
              textDayHeaderFontFamily: fonts.msRegular,
              textDayFontSize: 15,
              textMonthFontSize: 16,
              textDayHeaderFontSize: 12
            }}
          />

          <View style={gStyle.spacer1} />

          <Divider />

          <View style={gStyle.spacer2} />

          <View>
            <TouchableOpacity
              activeOpacity={gStyle.activeOpacity}
              onPress={() => {
                // on iOS device, use Haptic feedback
                if (device.iOS) Haptics.impactAsync(Light);

                const toggle = selectedRange === 'morning' ? null : 'morning';
                setSelectedRange(toggle);
              }}
              style={
                selectedRange === 'morning' ? styles.active : styles.select
              }
            >
              <Text style={styles.selectText}>Morning</Text>
            </TouchableOpacity>

            <View style={gStyle.spacer1} />

            <TouchableOpacity
              activeOpacity={gStyle.activeOpacity}
              onPress={() => {
                // on iOS device, use Haptic feedback
                if (device.iOS) Haptics.impactAsync(Light);

                const toggle = selectedRange === 'evening' ? null : 'evening';
                setSelectedRange(toggle);
              }}
              style={
                selectedRange === 'evening' ? styles.active : styles.select
              }
            >
              <Text style={styles.selectText}>Evening</Text>
            </TouchableOpacity>
          </View>

          <View style={gStyle.spacer2} />

          <Divider />

          <View style={gStyle.spacer2} />

          <Image source={images.simpletire} style={styles.image} />

          <View style={gStyle.spacer1} />

          <Text style={styles.text}>
            Provided by our trusted partner SimpleTire.
          </Text>

          <View style={gStyle.spacer2} />
        </View>
      </ScrollView>

      <View style={styles.contanerButtons}>
        <Button
          // onPress={() => navigation.navigate('ViewItem')}
          onPress={() =>
            selectedDay === null
              ? null
              : navigation.navigate('ViewItem', { item, itemId })
          }
          text="Confirm day and time"
        />

        <View style={gStyle.spacer2} />

        <Button
          onPress={() => navigation.goBack()}
          text="Change installer"
          type="secondary"
        />

        <View style={gStyle.spacer2} />
      </View>
    </React.Fragment>
  );
}

TireInstallSchedule.propTypes = {
  // required
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flex1,
    backgroundColor: colors.n0
  },
  heading: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 16,
    lineHeight: 24
  },
  textMuted: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 18,
    maxWidth: 320
  },
  textBold: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 12,
    lineHeight: 18
  },
  select: {
    ...gStyle.pH2,
    ...gStyle.pV1,
    backgroundColor: colors.n0,
    borderColor: colors.n2,
    borderRadius: 8,
    borderWidth: 1
  },
  active: {
    ...gStyle.pH2,
    ...gStyle.pV1,
    backgroundColor: colors.n1,
    borderColor: colors.n7,
    borderRadius: 8,
    borderWidth: 1
  },
  selectText: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 16
  },
  image: {
    height: 16,
    width: 82
  },
  contanerButtons: {
    ...gStyle.p2,
    backgroundColor: colors.n0
  }
});

export default TireInstallSchedule;
