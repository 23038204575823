import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgSort2({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 16 16" width={size}>
      <Path
        d="M4 3a.75.75 0 0 1 .75.75v6.545l.94-1.046a.75.75 0 0 1 1.116 1.003L4.569 12.74a.75.75 0 0 1-1.137 0l-2.24-2.488A.75.75 0 0 1 2.308 9.25l.943 1.047V3.751A.75.75 0 0 1 4 3ZM10.308 6.756a.75.75 0 1 1-1.116-1.003l2.25-2.504a.75.75 0 0 1 1.18.08l2.184 2.419a.75.75 0 1 1-1.112 1.006l-.944-1.045v6.542a.75.75 0 1 1-1.5 0V5.707l-.942 1.049Z"
        fill={fill}
      />
    </Svg>
  );
}

SvgSort2.defaultProps = {
  fill: colors.n7,
  size: 16
};

SvgSort2.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgSort2);
