import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgHeartFilled({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M21.262 3.857a5.81 5.81 0 00-8.524 0l-.74.778-.74-.778a5.81 5.81 0 00-8.525 0 6.508 6.508 0 000 8.844l8.544 8.985a1 1 0 001.45 0l8.545-8.985a6.508 6.508 0 00-.01-8.844z"
        fill={fill}
      />
    </Svg>
  );
}

SvgHeartFilled.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgHeartFilled.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgHeartFilled);
