import * as React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from 'react-native';
import { colors, fonts, func, gStyle } from '../constants';

// app state
import Context from '../context';

function CardItemOverview({ item, selected }) {
  // app state
  const { deliveryRange, locale, localeStrings } = React.useContext(Context);

  // translations
  const quantitySetTires = localeStrings('quantitySetTires', locale);
  const estimatedDelivery = localeStrings('estimatedDelivery', locale);

  const { image, price, title } = item;

  const isGerman = locale === 'de';
  const priceFormat = isGerman ? func.usdToEuro(price) : `$${price.toFixed(2)}`;

  return (
    <View style={styles.container}>
      <View style={styles.containerContent}>
        <Image source={{ uri: image }} style={styles.image} />

        <View style={styles.containerTextRight}>
          <Text style={styles.text} numberOfLines={1}>
            {title}
          </Text>

          <Text style={styles.text}>
            <Text style={styles.textBold}>{`${priceFormat}`}</Text>
            {` | ${quantitySetTires}`}
          </Text>

          <View style={gStyle.spacerHalf} />

          <Text style={styles.textMuted} numberOfLines={1}>
            {`${estimatedDelivery} `}
            <Text style={styles.textBold}>{deliveryRange}</Text>
          </Text>

          {selected !== null && (
            <React.Fragment>
              <View style={gStyle.spacer1} />
              <Text style={styles.text}>{selected.title}</Text>
              <Text style={styles.text}>{selected.address1}</Text>
              <Text style={styles.text}>{selected.address2}</Text>
            </React.Fragment>
          )}
        </View>
      </View>
    </View>
  );
}

CardItemOverview.defaultProps = {
  selected: null
};

CardItemOverview.propTypes = {
  // required
  item: PropTypes.object.isRequired,

  // optional
  selected: PropTypes.object
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.p2,
    backgroundColor: colors.n0
  },
  containerContent: {
    ...gStyle.flexRow,
    ...gStyle.p2,
    backgroundColor: colors.n1,
    borderRadius: 16,
    justifyContent: 'space-between'
  },
  image: {
    ...gStyle.mR2,
    backgroundColor: colors.n2,
    borderRadius: 8,
    height: 64,
    width: 64
  },
  containerTextRight: {
    flex: 1
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 18
  },
  textBold: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 12,
    lineHeight: 18
  },
  textMuted: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 18
  }
});

export default React.memo(CardItemOverview);
