import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgSettings({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M9.763 0h4.473c.423 0 .797.276.93.687l.695 2.132c.267.116.528.245.782.384l2.061-.773a.969.969 0 01.338-.062c.292 0 .576.135.767.379l2.788 3.579c.264.337.287.809.057 1.173l-1.202 1.895c.076.28.14.563.192.851l1.885 1.173c.36.224.54.66.447 1.08l-.995 4.464a.989.989 0 01-.862.777l-2.197.22c-.168.235-.345.463-.531.681l.28 2.242a1.01 1.01 0 01-.548 1.032l-4.028 1.987a.969.969 0 01-1.13-.205l-1.54-1.624c-.059.002-.116.006-.174.01a3.61 3.61 0 01-.25.011c-.086 0-.17-.006-.252-.011-.058-.004-.115-.008-.172-.01l-1.54 1.625a.97.97 0 01-1.13.205l-4.03-1.987c-.38-.187-.6-.602-.547-1.031l.28-2.243a10.103 10.103 0 01-.531-.68l-2.199-.22a.991.991 0 01-.86-.777l-.995-4.462A1.013 1.013 0 01.47 11.42l1.885-1.173c.05-.29.117-.572.191-.852L1.344 7.5a1.023 1.023 0 01.057-1.173l2.789-3.58a.97.97 0 011.104-.317l2.06.773c.256-.14.518-.268.785-.385L8.833.687A.982.982 0 019.763 0zm4.256 3.462L13.544 2h-3.088l-.474 1.462c-.18.55-.58.996-1.1 1.225-.217.096-.43.201-.632.312a1.948 1.948 0 01-1.621.119l-1.41-.53-1.925 2.474.824 1.305c.308.485.398 1.083.25 1.642a7.991 7.991 0 00-.156.69 2.033 2.033 0 01-.92 1.372L2 12.876l.686 3.088 1.51.15a1.967 1.967 0 011.408.825c.143.2.284.382.428.552.372.437.543 1.017.472 1.592l-.191 1.543L9.094 22l1.058-1.12a1.956 1.956 0 011.422-.615h.854c.534 0 1.047.22 1.422.616l1.058 1.118 2.782-1.374-.192-1.543a2.061 2.061 0 01.471-1.592c.145-.17.285-.351.428-.552a1.969 1.969 0 011.408-.824l1.51-.153.685-3.087-1.292-.804a2.035 2.035 0 01-.92-1.373 8.335 8.335 0 00-.154-.689 2.07 2.07 0 01.248-1.643l.824-1.304-1.925-2.475-1.41.53a1.931 1.931 0 01-1.622-.118 7.794 7.794 0 00-.631-.311 2.014 2.014 0 01-1.099-1.225zM7 12a5 5 0 015-5 5 5 0 015 5 5 5 0 01-5 5 5 5 0 01-5-5zm8 0c0-1.654-1.346-3-3-3s-3 1.346-3 3 1.346 3 3 3 3-1.346 3-3z"
        fill={fill}
      />
    </Svg>
  );
}

SvgSettings.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgSettings.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgSettings);
