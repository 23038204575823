import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgEdit({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M16.206 1.166a3.98 3.98 0 115.628 5.628L7.976 20.653a.996.996 0 01-.509.272L2.191 21.98a.995.995 0 01-1.171-1.17l1.055-5.277a.998.998 0 01.272-.509L16.206 1.166zM17.613 8.2l-2.814-2.814L3.541 16.644l-.703 3.518 3.518-.703L17.613 8.2zm2.814-5.628a1.99 1.99 0 00-2.814 0L16.206 3.98l2.814 2.814 1.407-1.407a1.99 1.99 0 000-2.814z"
        fill={fill}
      />
    </Svg>
  );
}

SvgEdit.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgEdit.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgEdit);
