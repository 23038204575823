import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { gStyle } from '../constants';

// screens
import HomeScreen from '../screens/Home';

// components
import HeaderRight from '../components/HeaderRight';

// icons
import { SvgCart, SvgLogo } from '../icons';

const Stack = createStackNavigator();

const icon = <SvgLogo />;

function StackHome() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerRight: () => <HeaderRight icon={<SvgCart />} />,
          headerStyle: gStyle.navHeaderContainerStyle,
          headerTitle: () => icon,
          headerTitleAlign: 'center',
          headerTitleStyle: gStyle.navHeaderTitleStyle
        }}
      />
    </Stack.Navigator>
  );
}

export default StackHome;
