import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgDeals({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M11.996 23.993a1 1 0 01-1-1v-8h-7a.999.999 0 01-.85-1.52l8-13a1 1 0 011.85.52v8h7a1 1 0 01.85 1.52l-8 13a1 1 0 01-.85.48zm-1-19.47l-5.21 8.47h6.21a1 1 0 011 1v5.47l5.21-8.47h-6.21a1 1 0 01-1-1v-5.47z"
        fill={fill}
      />
    </Svg>
  );
}

SvgDeals.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgDeals.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgDeals);
