import * as React from 'react';
import { View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { device } from '../constants';

// stack
import StackMain from './StackMain';

// screens
import DrawerFilter from '../screens/DrawerFilter';

const Drawer = createDrawerNavigator();

function App() {
  return (
    <NavigationContainer>
      <View style={{ flex: 1, overflowX: 'hidden' }}>
        <Drawer.Navigator
          drawerContent={(props) => <DrawerFilter {...props} />}
          screenOptions={{
            drawerPosition: 'right',
            drawerStyle: {
              width: device.width - 72
            },
            drawerType: 'front'
          }}
        >
          <Drawer.Screen
            name="StackMain"
            component={StackMain}
            options={{ headerShown: false }}
          />
        </Drawer.Navigator>
      </View>
    </NavigationContainer>
  );
}

export default App;
