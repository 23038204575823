import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import * as Haptics from 'expo-haptics';
import { colors, device, fonts, gStyle, images } from '../constants';

// components
import Button from '../components/Button';
import CardLocation from '../components/CardLocation';

// icons
import { SvgClose, SvgMarkerBottom } from '../icons';

// data
import tireShops from '../data/tireShops.json';

// app state
import Context from '../context';

const { Light } = Haptics.ImpactFeedbackStyle;

function ModalMap({ navigation, route }) {
  // app state
  const cntx = React.useContext(Context);
  const { locale, localeStrings, locationKey, setLocationKey, zip } = cntx;

  const { item } = route.params;

  // translations
  const chooseThisInstaller = localeStrings('chooseThisInstaller', locale);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={images.map}
        resizeMode="cover"
        style={styles.image}
      >
        <View style={styles.containerMarkers}>
          {Object.keys(tireShops).map((key) => {
            const { id, title, price, left, top } = tireShops[key];
            const pos = { left, top };

            const isSelected = locationKey === key;
            const backgroundColor = isSelected ? colors.b4 : colors.n0;
            const color = isSelected ? colors.n0 : colors.n7;

            return (
              <TouchableOpacity
                activeOpacity={1}
                onPress={() => {
                  // on iOS device, use Haptic feedback
                  if (device.iOS) Haptics.impactAsync(Light);

                  // allow for toggle
                  const newValue = isSelected ? null : key;

                  setLocationKey(newValue);
                }}
                style={[styles.marker, pos]}
                key={id}
              >
                <View style={[styles.markContent, { backgroundColor }]}>
                  <Text style={[styles.markerText, { color }]}>{title}</Text>
                  <Text
                    style={[styles.markerPrice, { color }]}
                  >{`+ $${price}`}</Text>
                </View>

                <View style={styles.markBottom}>
                  <SvgMarkerBottom fill={backgroundColor} />
                </View>
              </TouchableOpacity>
            );
          })}
        </View>

        <View style={styles.containerHeader}>
          <TouchableOpacity
            activeOpacity={gStyle.activeOpacity}
            onPress={() => navigation.goBack()}
            style={[gStyle.headingIcon, gStyle.dropshadow]}
          >
            <SvgClose />
          </TouchableOpacity>

          <View style={styles.containerZip}>
            <Text style={styles.zip}>{zip}</Text>
          </View>
        </View>

        {locationKey !== null && (
          <View style={styles.containerBottom}>
            <CardLocation {...tireShops[locationKey]} selected />

            <View style={gStyle.spacer4} />

            <Button
              onPress={() => {
                navigation.goBack();
                navigation.navigate('TireInstallSchedule', { item });
              }}
              text={chooseThisInstaller}
            />
          </View>
        )}
      </ImageBackground>
    </View>
  );
}

ModalMap.propTypes = {
  // required
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flex1,
    backgroundColor: colors.n0
  },
  image: {
    height: '100%',
    width: '100%'
  },
  containerHeader: {
    ...gStyle.flexRowSpace,
    ...gStyle.mH2,
    marginTop: device.iPhoneNotch ? 46 : 16
  },
  containerZip: {
    ...gStyle.dropshadow,
    ...gStyle.pH2,
    backgroundColor: colors.n1,
    borderRadius: 20,
    height: 40,
    justifyContent: 'center'
  },
  zip: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 16,
    lineHeight: 18
  },
  containerMarkers: {
    height: '100%',
    position: 'absolute',
    width: '100%'
  },
  marker: {
    ...gStyle.dropshadow,
    backgroundColor: colors.n0,
    borderRadius: 8,
    position: 'absolute'
  },
  markContent: {
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 8
  },
  markerText: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  markerPrice: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 12,
    lineHeight: 16
  },
  markBottom: {
    alignItems: 'center',
    bottom: -6,
    position: 'absolute',
    width: '100%'
  },
  containerBottom: {
    backgroundColor: colors.n0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    bottom: 0,
    left: 0,
    paddingBottom: device.iPhoneNotch ? 46 : 16,
    paddingHorizontal: 16,
    paddingTop: 48,
    position: 'absolute',
    width: '100%'
  }
});

export default ModalMap;
