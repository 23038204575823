import * as React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { colors, fonts, func, gStyle } from '../constants';

// icons
import { SvgHeartOutline } from '../icons';

// app state
import Context from '../context';

function ResultHorizontal(props) {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  // props
  const { condition, image, itemId, price, promoted, title } = props;
  const navigation = useNavigation();

  // translations
  const freeShipping = localeStrings('freeShipping', locale);

  /*
  const promotedText = localeStrings('promoted', locale);

  {promoted && (
    <React.Fragment>
      <View style={gStyle.spacer2} />
      <Text style={styles.muted}>{promotedText}</Text>
    </React.Fragment>
  )}
  */

  const item = { condition, image, price, promoted, title };

  const isGerman = locale === 'de';
  const priceFormat = isGerman ? func.usdToEuro(price) : `$${price}`;

  return (
    <TouchableOpacity
      activeOpacity={gStyle.activeOpacity}
      onPress={() => navigation.navigate('ViewItem', { item, itemId })}
      style={styles.container}
    >
      <View>
        <Image source={{ uri: image }} style={styles.image} />
        <View style={styles.scrim} />
      </View>

      <View style={styles.content}>
        <Text ellipsizeMode="tail" numberOfLines={2} style={styles.title}>
          {title}
        </Text>
        <Text style={styles.muted}>{condition}</Text>
        <View style={gStyle.spacer1} />

        <Text style={styles.price}>{priceFormat}</Text>
        <Text style={styles.muted}>{freeShipping}</Text>

        <TouchableOpacity
          activeOpacity={gStyle.activeOpacity}
          hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
          onPress={() => null}
          style={styles.containerIcon}
        >
          <SvgHeartOutline fill={colors.n5} size={16} />
        </TouchableOpacity>
      </View>
    </TouchableOpacity>
  );
}

ResultHorizontal.propTypes = {
  // required
  condition: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  promoted: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flexRow,
    ...gStyle.mB2,
    ...gStyle.pB2,
    ...gStyle.mH2,
    borderBottomColor: colors.n2,
    borderBottomWidth: 1,
    flexWrap: 'wrap'
  },
  scrim: {
    backgroundColor: colors.imageOverlay,
    borderRadius: 12,
    height: 134,
    position: 'absolute',
    width: 134
  },
  image: {
    backgroundColor: colors.imageOverlay,
    borderRadius: 12,
    height: 134,
    width: 134
  },
  content: {
    ...gStyle.pL2,
    flex: 1,
    justifyContent: 'center'
  },
  title: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    lineHeight: 20
  },
  muted: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  price: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 16,
    lineHeight: 24
  },
  containerIcon: {
    bottom: 0,
    position: 'absolute',
    right: 0
  }
});

export default React.memo(ResultHorizontal);
