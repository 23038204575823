import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import { colors, device, fonts, gStyle } from '../constants';

// icons
import { SvgFilter, SvgHeartOutline, SvgSort } from '../icons';

// app state
import Context from '../context';

const { Medium } = Haptics.ImpactFeedbackStyle;

function SearchSortFilter() {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  const navigation = useNavigation();

  // translations
  const saveThisSearch = localeStrings('saveThisSearch', locale);
  const sort = localeStrings('sort', locale);

  return (
    <View style={styles.container}>
      <View style={styles.containeLeft}>
        <SvgHeartOutline fill={colors.b4} size={18} />
        <Text style={styles.text}>{saveThisSearch}</Text>
      </View>

      <View style={styles.containeRight}>
        <SvgSort />
        <Text style={styles.text}>{sort}</Text>

        <View style={gStyle.spacer2W} />

        <SvgFilter />
        <TouchableOpacity
          activeOpacity={gStyle.activeOpacity}
          onPress={() => {
            // on iOS device, use Haptic feedback
            if (device.iOS) Haptics.impactAsync(Medium);

            navigation.openDrawer();
          }}
        >
          <Text style={styles.text}>Filter</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...gStyle.pB2,
    ...gStyle.pH2,
    alignItems: 'center',
    borderBottomColor: colors.n2,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  containeLeft: {
    ...gStyle.flexRow
  },
  containeRight: {
    ...gStyle.flexRowAlignCenter
  },
  text: {
    ...gStyle.mL1,
    color: colors.b4,
    fontFamily: fonts.msBold
  }
});

export default React.memo(SearchSortFilter);
