import * as React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { colors, gStyle } from '../constants';

// icons
import { SvgStarFilled, SvgStarHalf } from '../icons';

function StarBar({ rating, outOf }) {
  const starsArray = new Array(outOf).fill(1);
  // checks if we have integar
  const isIntegar = Number.isInteger(rating);

  // if float, mark the Half Star usage
  // 2.1, 2.3, etc rounds up all
  // (no real spec on usage for half star, so all handled)
  const halfIndex = isIntegar === false ? Math.ceil(rating) : null;

  return (
    <View style={gStyle.flexRow}>
      {starsArray.map((star, index) => {
        const indexPlus = index + 1;
        const isFilled = indexPlus <= rating;
        const showHalfStar = halfIndex !== null && indexPlus === halfIndex;
        const fill = showHalfStar || isFilled ? colors.n7 : colors.n3;

        // show half star?
        const Icon = showHalfStar ? SvgStarHalf : SvgStarFilled;

        return <Icon key={indexPlus} fill={fill} size={16} />;
      })}
    </View>
  );
}

StarBar.defaultProps = {
  outOf: 5
};

StarBar.propTypes = {
  // required
  rating: PropTypes.number.isRequired,

  // optional
  outOf: PropTypes.number
};

export default React.memo(StarBar);
