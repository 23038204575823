import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { colors, device, fonts, func, gStyle } from '../constants';

// components
import Button from '../components/Button';
import CardFits from '../components/CardFits';
import CardTire from '../components/CardTire';
import Divider from '../components/Divider';
import MenuItemFilter from '../components/MenuItemFilter';
import StarBar from '../components/StarBar';

// icons
import { SvgChevronRight } from '../icons';

// app state
import Context from '../context';

// data
import tireShops from '../data/tireShops.json';

function ViewItem({ navigation, route }) {
  // app state
  const cntx = React.useContext(Context);
  const { deliveryRange, locale, localeStrings, locationKey } = cntx;

  const { item, itemId } = route.params;
  const { image, price, title } = item;

  // translations
  const productRatings = localeStrings('productRatings', locale);
  const freeShipping = localeStrings('freeShipping', locale);
  const buyerSatisfaction = localeStrings('buyerSatisfaction', locale);
  const sold = localeStrings('sold', locale);
  const quantity4Items = localeStrings('quantity4Items', locale);
  const tireInstallAv = localeStrings('tireInstallAv', locale);
  const findServiceNear = localeStrings('findServiceNear', locale);
  const buyItNow = localeStrings('buyItNow', locale);
  const addToCart = localeStrings('addToCart', locale);
  const addToWatchlist = localeStrings('addToWatchlist', locale);

  const isGerman = locale === 'de';
  const priceFormat = isGerman ? func.usdToEuro(price) : `$${price.toFixed(2)}`;

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      <CardTire />

      <CardFits />

      <View style={styles.containerImage}>
        <Image source={{ uri: image }} style={styles.image} />
      </View>

      <View style={gStyle.spacer3} />

      <View style={gStyle.pH2}>
        <Text style={styles.title}>{title}</Text>

        <View style={gStyle.spacer1} />

        <View style={gStyle.flexRowAlignCenter}>
          <StarBar rating={5} />

          <Text style={styles.productRatings}>{`59 ${productRatings}`}</Text>
        </View>

        <View style={gStyle.spacer3} />

        <View style={gStyle.flexRowAlignCenter}>
          <Text style={styles.price}>{priceFormat}</Text>
          <Text style={styles.freeShipping}>{freeShipping}</Text>
        </View>

        <View style={gStyle.spacer3} />

        <Text
          style={styles.deliveryDate}
        >{`Est. delivery ${deliveryRange}`}</Text>

        <View style={gStyle.spacer3} />

        <Divider />
      </View>

      <TouchableOpacity
        activeOpacity={1}
        onPress={() => null}
        style={styles.menuItemRating}
      >
        <Text style={styles.rating} numberOfLines={1}>
          {`100% ${buyerSatisfaction}`}
        </Text>
        <View style={styles.dividerBar} />
        <Text style={styles.sold} numberOfLines={1}>
          {`22 ${sold}`}
        </Text>
      </TouchableOpacity>

      <MenuItemFilter text={quantity4Items} value="1" />

      <TouchableOpacity
        activeOpacity={gStyle.activeOpacity}
        onPress={() => navigation.navigate('TireInstall', { item, itemId })}
        style={styles.menuItemInstall}
      >
        <View>
          <Text style={styles.installBold} numberOfLines={1}>
            {tireInstallAv}
          </Text>
          <View style={gStyle.spacerHalf} />

          {locationKey === null && (
            <Text style={styles.installHighlight} numberOfLines={1}>
              {findServiceNear}
            </Text>
          )}

          {locationKey !== null && (
            <View style={gStyle.flexRowAlignCenter}>
              <Text style={styles.service} numberOfLines={1}>
                {'Service provided by '}
              </Text>
              <Text style={styles.installHighlight} numberOfLines={1}>
                {`${tireShops[locationKey].title} for $${tireShops[locationKey].price}`}
              </Text>
            </View>
          )}
        </View>

        <View style={styles.menuItemInstallRight}>
          <SvgChevronRight size={16} />
        </View>
      </TouchableOpacity>

      <View style={gStyle.pH2}>
        <View style={gStyle.spacer3} />

        <Button
          onPress={() => navigation.navigate('Checkout', { item, itemId })}
          text={buyItNow}
        />

        <View style={gStyle.spacer2} />

        <Button
          // onPress={() => navigation.navigate('Checkout', { item, itemId })}
          onPress={() => null}
          text={addToCart}
          type="secondary"
        />

        <View style={gStyle.spacer2} />

        <Button text={addToWatchlist} type="secondary" />

        <View style={gStyle.spacer2} />
      </View>
    </ScrollView>
  );
}

ViewItem.propTypes = {
  // required
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flex1,
    backgroundColor: colors.n0
  },
  containerImage: {
    borderBottomColor: colors.n2,
    borderBottomWidth: 1
  },
  image: {
    height: 300,
    resizeMode: 'contain',
    width: device.width
  },
  title: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 16
  },
  productRatings: {
    ...gStyle.mL2,
    color: colors.b4,
    fontFamily: fonts.msRegular,
    fontSize: 12
  },
  price: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 18
  },
  freeShipping: {
    ...gStyle.mL1,
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  deliveryDate: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  menuItemRating: {
    ...gStyle.flexRowSpace,
    ...gStyle.mH2,
    ...gStyle.pV2,
    borderBottomColor: colors.n2,
    borderBottomWidth: 1
  },
  rating: {
    color: colors.n7,
    flex: 3,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  dividerBar: {
    backgroundColor: colors.n2,
    height: 24,
    width: 1
  },
  sold: {
    color: colors.n7,
    flex: 2,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    textAlign: 'center'
  },
  menuItemInstall: {
    ...gStyle.flexRowSpace,
    ...gStyle.mH2,
    ...gStyle.pV2,
    borderBottomColor: colors.n2,
    borderBottomWidth: 1
  },
  installBold: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 14
  },
  installHighlight: {
    color: colors.b4,
    fontFamily: fonts.msBold,
    fontSize: 14,
    textDecorationLine: 'underline'
  },
  service: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    marginRight: 4
  },
  menuItemInstallRight: {
    ...gStyle.flexRowAlignCenter,
    flex: 2,
    justifyContent: 'flex-end'
  }
});

export default ViewItem;
