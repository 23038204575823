import * as React from 'react';
import PropTypes from 'prop-types';
import { Image, ScrollView, StyleSheet, Text, View } from 'react-native';
import { colors, fonts, gStyle, images } from '../constants';

// components
import CardItemCheckoutSuccess from '../components/CardItemCheckoutSuccess';
import Divider from '../components/Divider';

// app state
import Context from '../context';

function CheckoutSuccess({ route }) {
  // app state
  const { deliveryEndShort, locationKey } = React.useContext(Context);
  const { item } = route.params;

  const priceTotal = item.price;
  const taxTotal = priceTotal * 0.0475;
  const orderTotal = priceTotal + taxTotal + 1;

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      <View style={gStyle.spacer2} />

      <View style={styles.containerHeader}>
        <Text style={styles.header}>Your order&apos;s in!</Text>
        <Text style={styles.paragraph}>
          Your order will arrive to the installer by{' '}
          <Text style={styles.paragraphBold}>{deliveryEndShort}</Text>. Our
          partner SimpleTire will email you to confirm your installation
          drop-off day and time.
        </Text>
      </View>

      <View style={gStyle.pH2}>
        <View style={gStyle.spacer3} />

        <Text style={styles.orderHeading}>
          {`Order total: $${orderTotal.toFixed(2)}`}
        </Text>

        <View style={gStyle.spacer3} />

        <Divider />

        <View style={gStyle.spacer3} />

        <View style={styles.containerShip}>
          <View style={styles.containerMuted}>
            <Text style={styles.textMuted}>Shipping to</Text>
          </View>

          <View style={styles.shipMiddle}>
            <Text style={styles.textReg}>Pep Boys c/o Matthew Smith</Text>
            <Text style={styles.textReg}>4441 Fauntleroy Way SW</Text>
            <Text style={styles.textReg}>Seattle, WA 98126</Text>
            <Text style={styles.textReg}>United States</Text>
          </View>
        </View>

        <View style={gStyle.spacer4} />

        <Text style={styles.text}>Order no.: 36-53162-75335</Text>

        <View style={gStyle.spacer2} />

        <CardItemCheckoutSuccess item={item} />

        <View style={gStyle.spacer2} />

        <Text style={styles.textPrimary}>See order details</Text>

        <View style={gStyle.spacer3} />
      </View>

      {locationKey !== null && (
        <View style={gStyle.pH2}>
          <Text style={styles.text}>Order no.: 84-53162-75374</Text>

          <View style={gStyle.spacer2} />

          <View style={styles.containerInstall}>
            <View style={styles.containerImage}>
              <Image source={images.simpletire} style={styles.image} />
              <View style={styles.scrim} />
            </View>

            <View style={styles.installMiddle}>
              <Text style={styles.text}>
                Tire Installation - 4 Tire(s) - 68058 - Passenger
              </Text>
            </View>
          </View>
        </View>
      )}

      <View style={gStyle.spacer3} />
    </ScrollView>
  );
}

CheckoutSuccess.propTypes = {
  // required
  route: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flex1,
    backgroundColor: colors.n0
  },
  containerHeader: {
    alignItems: 'center',
    backgroundColor: colors.t2,
    paddingVertical: 24
  },
  header: {
    color: colors.b7,
    fontFamily: fonts.msBold,
    fontSize: 30,
    lineHeight: 40,
    marginBottom: 8
  },
  paragraph: {
    color: colors.b7,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    lineHeight: 20,
    maxWidth: 340,
    textAlign: 'center'
  },
  paragraphBold: {
    fontFamily: fonts.msBold
  },
  orderHeading: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 20,
    lineHeight: 28
  },
  containerInstall: {
    flexDirection: 'row'
  },
  containerImage: {
    alignItems: 'center',
    height: 72,
    justifyContent: 'center',
    width: 72
  },
  image: {
    height: 12,
    width: 62
  },
  scrim: {
    backgroundColor: colors.imageOverlay,
    borderRadius: 12,
    height: 72,
    position: 'absolute',
    width: 72
  },
  installMiddle: {
    ...gStyle.mH2,
    flex: 1
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  containerShip: {
    flexDirection: 'row'
  },
  containerMuted: {
    ...gStyle.mR2,
    height: 72,
    width: 72
  },
  textMuted: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  textReg: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    lineHeight: 20
  },
  textPrimary: {
    color: colors.b4,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  }
});

export default CheckoutSuccess;
