import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgMic({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M17 10a5 5 0 01-10 0V6a5 5 0 0110 0v4zm-5 3a3 3 0 003-3V6a3 3 0 10-6 0v4a3 3 0 003 3zm6.797.021a1 1 0 011.033 1.529A9.002 9.002 0 0113 18.94V21h2a1 1 0 010 2H9a1 1 0 010-2h2v-2.06a9 9 0 01-6.83-4.38 1 1 0 111.66-1.11A7 7 0 0012 17a7 7 0 006.16-3.55c.147-.222.376-.376.637-.429z"
        fill={fill}
      />
    </Svg>
  );
}

SvgMic.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgMic.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgMic);
