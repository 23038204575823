import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { colors, device, fonts, gStyle } from '../constants';

// icons
import { SvgClose, SvgTriangle } from '../icons';

function ToolTip() {
  const [shown, setShown] = React.useState(true);

  if (shown === false) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.containerTip}>
        <View style={styles.containerTriangle}>
          <SvgTriangle />
        </View>

        <Text style={styles.heading}>Need your tires installed?</Text>
        <Text style={styles.text}>
          Filter results to find tires eligible for tire installation at a local
          service center.
        </Text>

        <TouchableOpacity
          activeOpacity={gStyle.activeOpacity}
          onPress={() => setShown(false)}
          style={styles.containerClose}
        >
          <SvgClose />
        </TouchableOpacity>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    left: 0,
    position: 'absolute',
    top: device.iPhoneNotch ? 140 : 100,
    width: '100%',
    zIndex: 1
  },
  containerTip: {
    ...gStyle.dropshadow,
    ...gStyle.mT1,
    ...gStyle.mL2,
    ...gStyle.p2,
    backgroundColor: colors.n0,
    borderRadius: 16,
    width: device.width - 32
  },
  containerTriangle: {
    position: 'absolute',
    right: 16,
    top: -6
  },
  heading: {
    ...gStyle.mB1,
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 20,
    lineHeight: 28
  },
  text: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    lineHeight: 20
  },
  containerClose: {
    position: 'absolute',
    right: 16,
    top: 16
  }
});

export default React.memo(ToolTip);
