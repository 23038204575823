import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import locales from './index';

i18n.fallbacks = true;
i18n.translations = locales;
i18n.locale = Localization.locale;

export function strings(name, override = null, params = {}) {
  if (override) {
    i18n.locale = override;
  }

  return i18n.t(name, params);
}

export default i18n;
