import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { colors } from '../constants';

function RadioCircle({ disabled, selected }) {
  const borderColor = disabled ? colors.n2 : colors.n7;

  return (
    <View style={[styles.container, { borderColor }]}>
      {selected && <View style={styles.selected} />}
    </View>
  );
}

RadioCircle.defaultProps = {
  disabled: false,
  selected: false
};

RadioCircle.propTypes = {
  // optional
  disabled: PropTypes.bool,
  selected: PropTypes.bool
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderRadius: 12,
    borderWidth: 1,
    height: 24,
    justifyContent: 'center',
    width: 24
  },
  selected: {
    backgroundColor: colors.n7,
    borderRadius: 7,
    height: 14,
    width: 14
  }
});

export default React.memo(RadioCircle);
