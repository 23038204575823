import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgThreeDotVert({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M12 7a2 2 0 10.001-3.999A2 2 0 0012 7zm0 7a2 2 0 10.001-3.999A2 2 0 0012 14zm2 5a2 2 0 11-3.999.001A2 2 0 0114 19z"
        fill={fill}
      />
    </Svg>
  );
}

SvgThreeDotVert.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgThreeDotVert.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgThreeDotVert);
