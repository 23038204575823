import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// components
import RadioCircle from './RadioCircle';

function MenuItemRadio({ disabled, noDivider, onPress, selected, text }) {
  const borderBottomWidth = noDivider ? 0 : 1;
  const color = disabled ? { color: colors.n2 } : {};

  return (
    <TouchableOpacity
      activeOpacity={gStyle.activeOpacity}
      onPress={onPress}
      style={[styles.container, { borderBottomWidth }]}
    >
      <RadioCircle disabled={disabled} selected={selected} />

      <Text style={[styles.text, color]}>{text}</Text>
    </TouchableOpacity>
  );
}

MenuItemRadio.defaultProps = {
  disabled: false,
  noDivider: false,
  onPress: () => null,
  selected: false
};

MenuItemRadio.propTypes = {
  // required
  text: PropTypes.string.isRequired,

  // optional
  disabled: PropTypes.bool,
  noDivider: PropTypes.bool,
  onPress: PropTypes.func,
  selected: PropTypes.bool
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flexRowAlignCenter,
    ...gStyle.mH2,
    ...gStyle.pV2,
    borderBottomColor: colors.n2
  },
  text: {
    ...gStyle.mL1,
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  }
});

export default React.memo(MenuItemRadio);
