import React from 'react';

export default React.createContext({
  deliveryStart: null,
  deliveryEndShort: null,
  deliveryRange: null,
  todaysDate: null,
  locale: 'en',
  locationKey: null,
  radius: 100,
  selectedDay: null,
  showInstallListings: true,
  zip: '98125'
});
