import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgFilter({ fill, width }) {
  // get height and keep aspect ratio
  const height = Math.ceil((11 / 12) * width);

  return (
    <Svg height={height} viewBox="0 0 12 11" width={width}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.836a1 1 0 00-1-1H5a1 1 0 000 2h2a1 1 0 001-1zm-7-7h10a1 1 0 100-2H1a1 1 0 000 2zm9 3a1 1 0 00-1-1H3a1 1 0 000 2h6a1 1 0 001-1z"
        fill={fill}
      />
    </Svg>
  );
}

SvgFilter.defaultProps = {
  fill: colors.b4,
  width: 12
};

SvgFilter.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.number
};

export default React.memo(SvgFilter);
