const gatewayURL = 'https://gateway.ebaydesign.tech/graphql';

/* eslint-disable */
// local grab, linting didn't like this
import { API_TOKEN } from '@env';
/* eslint-enable */

const headerPostBase = {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${API_TOKEN}`,
    'Content-Type': 'application/json',
    'Content-Security-Policy': "frame-ancestors 'self'"
  }
};

const autoSuggest = async (query) => {
  const endpoint = `https://www.ebay.com/autosug?kwd=${query}&_jgr=1&sId=0&_ch=0&_ps=1&callback=0`;
  const response = await fetch(endpoint);
  const responseJson = await response.json();

  if (responseJson?.res?.sug) {
    return responseJson.res.sug;
  }

  return [];
};

const itemById = async (id) => {
  // get listing by id
  try {
    const headers = {
      ...headerPostBase,
      body: JSON.stringify({
        query: `{
          item(id: "${id}") {
              itemId
              title
              brand
              categoryPath
              color
              condition
              price {
                value
              }
              localizedAspects {
                type
                name
                value
              }
              image {
                imageUrl
              }
            }
          }`
      })
    };

    const responseQL = await fetch(gatewayURL, headers);
    const gatewaySearch = await responseQL.json();
    const { item } = gatewaySearch.data;

    return item;
  } catch (error) {
    /* eslint-disable */
    // error on connection attempt
    console.error('CATCH ERROR: itemById()');
    console.error(error);
    console.error('--------------');
    /* eslint-enable */
    return {
      error: true
    };
  }
};

const itemsBySearch = async (query = 'pokemon') => {
  // get listings from keyword search
  try {
    const headers = {
      ...headerPostBase,
      body: JSON.stringify({
        query: `{
          itemsBySearch(q: "${query}", limit: 48) {
            items {
                itemId
                title
                condition
                price {
                  value
                }
                image {
                  imageUrl
                }
                priorityListing
                topRatedBuyingExperience
              }
            }
          }`
      })
    };

    const responseQL = await fetch(gatewayURL, headers);
    const gatewaySearch = await responseQL.json();
    const { itemsBySearch: results } = gatewaySearch.data;

    return results.items;
  } catch (error) {
    /* eslint-disable */
    // error on connection attempt
    console.error('CATCH ERROR: itemsBySearch()');
    console.error(error);
    console.error('--------------');
    /* eslint-enable */
    return {
      error: true
    };
  }
};

export default {
  autoSuggest,
  itemById,
  itemsBySearch
};
