import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgEyeHidden({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M11.47 16c.176.01.353.01.53 0a4.49 4.49 0 004.5-4.5 4.546 4.546 0 00-.05-.53L11.47 16zM19.29 3.29L17.08 5.5A9.72 9.72 0 0012 4C5 4 1 12 1 12a17 17 0 004.66 4.92l-2.37 2.37 1.42 1.42 16-16-1.42-1.42zM3.36 11.92C4.56 10 7.63 6 12 6a7.6 7.6 0 013.62 1l-.94.94a4.48 4.48 0 00-6.28 6.24l-1.29 1.3a14.74 14.74 0 01-3.75-3.56zm6.49.82a2.41 2.41 0 01-.35-1.24A2.5 2.5 0 0112 9a2.41 2.41 0 011.24.35l-3.39 3.39z"
        fill={fill}
      />
      <Path
        d="M19.77 7.64l-1.41 1.42a17.28 17.28 0 012.28 2.87C19.4 13.61 16.36 17 12 17a8.503 8.503 0 01-1.45-.13L8.9 18.51A10.32 10.32 0 0012 19c7 0 11-7 11-7a19.34 19.34 0 00-3.23-4.36z"
        fill={fill}
      />
    </Svg>
  );
}

SvgEyeHidden.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgEyeHidden.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgEyeHidden);
