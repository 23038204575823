import { Alert, Image } from 'react-native';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as Updates from 'expo-updates';

import preloadFonts from './preloadFonts';
import preloadImages from './preloadImages';

// cache fonts
// /////////////////////////////////////////////////////////////////////////////
const cacheFonts = (fonts) => fonts.map((font) => Font.loadAsync(font));

// cache images
// /////////////////////////////////////////////////////////////////////////////
const cacheImages = (images) =>
  Object.values(images).map((image) => {
    if (typeof image === 'string') {
      return Image.prefetch(image);
    }

    return Asset.fromModule(image).downloadAsync();
  });

// preload async
// /////////////////////////////////////////////////////////////////////////////
const loadAssetsAsync = async () => {
  // preload assets
  const fontAssets = cacheFonts(preloadFonts);
  const imageAssets = cacheImages(preloadImages);

  // promise load all
  return Promise.all([...fontAssets, ...imageAssets]);
};

// wait/sleep function
// /////////////////////////////////////////////////////////////////////////////
const wait = (ms) => new Promise((r) => setTimeout(r, ms));

// check for Over the Air updates available
// /////////////////////////////////////////////////////////////////////////////
const checkOTAUpdate = async () => {
  const { isAvailable } = await Updates.checkForUpdateAsync();

  // if an update is available, display modal
  if (isAvailable) {
    Alert.alert(
      'Over-the-air Update Available',
      'An update to the eBay Tire Install app is required to continue.',
      [
        {
          text: 'Update',
          onPress: async () => {
            await Updates.reloadAsync();
          }
        }
      ],
      { cancelable: false }
    );
  }

  return true;
};

const milesToKm = (miles) => {
  const converted = miles * 1.609344;
  const localized = `${converted.toFixed(3)} km`.replace('.', ',');

  return localized;
};

const usdToEuro = (usd) => {
  const converted = usd * 0.93;
  const localized = `${converted.toFixed(2)}€`.replace('.', ',');

  return localized;
};

const getTodayDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const monthFormat = month < 10 ? `0${month}` : month;
  const year = date.getFullYear();
  const todaysDate = `${year}-${monthFormat}-${day}`;

  return todaysDate;
};

const getDateString = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const monthFormat = month < 10 ? `0${month}` : month;
  const year = newDate.getFullYear();
  const dateString = `${year}-${monthFormat}-${day}`;

  return dateString;
};

const getDateString2 = (date) => {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const monthFormat = month < 10 ? `0${month}` : month;
  const year = newDate.getFullYear().toString().substr(-2);
  const dateString = `${monthFormat}/${day}/${year}`;

  return dateString;
};

export default {
  cacheFonts,
  cacheImages,
  loadAssetsAsync,
  wait,
  checkOTAUpdate,
  milesToKm,
  usdToEuro,
  getTodayDate,
  getDateString,
  getDateString2
};
