import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { api, colors, device, fonts, gStyle } from '../constants';

// components
import CardTire from '../components/CardTire';
import LoadingAnimation from '../components/LoadingAnimation';
import ResultHorizontal from '../components/ResultHorizontal';
import SearchSortFilter from '../components/SearchSortFilter';
import ToolTip from '../components/ToolTip';

// icons
import { SvgCameraSm, SvgCart, SvgChevronLeft } from '../icons';

// app state
import Context from '../context';

function SearchResult({ route, navigation }) {
  const { searchText } = route.params;

  // app state
  const { setLocationKey, setSelectedDay } = React.useContext(Context);

  const [text, setText] = React.useState(searchText);
  const [isLoading, setLoading] = React.useState(true);
  const [listings, setListings] = React.useState([]);

  const getListings = async () => {
    const results = await api.itemsBySearch(searchText);
    // await func.wait(2000);
    setListings(results);
    setLoading(false);
  };

  useFocusEffect(
    React.useCallback(() => {
      setLocationKey(null);
      setSelectedDay(null);

      return () => {
        //
      };
    }, [])
  );

  React.useEffect(() => {
    // mount
    getListings();

    return () => {
      // unmount
    };
  }, []);

  const scrollRef = React.useRef();

  return (
    <React.Fragment>
      <View style={styles.containerSticky}>
        <View style={styles.notch} />

        <View style={gStyle.flexRowAlignCenter}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => navigation.goBack(null)}
            style={[gStyle.headingIcon, gStyle.mL1, gStyle.mR2]}
          >
            <SvgChevronLeft size={20} />
          </TouchableOpacity>

          <View style={styles.containerSearch}>
            <TextInput
              onChangeText={setText}
              placeholder="Search for anything"
              placeholderTextColor={colors.n7}
              selectionColor={colors.b4}
              style={styles.input}
              value={text}
            />

            <View style={styles.containerIcon}>
              <SvgCameraSm />
            </View>
          </View>

          <TouchableOpacity
            activeOpacity={1}
            onPress={() => null}
            style={[gStyle.headingIcon, gStyle.mL2, gStyle.mR1]}
          >
            <SvgCart size={18} />
          </TouchableOpacity>
        </View>

        <View style={gStyle.spacer2} />

        <SearchSortFilter />
      </View>

      {isLoading && <LoadingAnimation />}

      {listings !== null && (
        <React.Fragment>
          {isLoading === false && <ToolTip />}

          <ScrollView
            ref={scrollRef}
            keyboardShouldPersistTaps="never"
            showsVerticalScrollIndicator={false}
            style={gStyle.containerBg}
          >
            {isLoading === false && <CardTire />}

            <View style={gStyle.spacer2} />

            {listings.map((listing) => {
              const { condition, image, itemId, price, title } = listing;
              const imageURI =
                image?.imageUrl ||
                'https://i.ebayimg.com/thumbs/images/g/1ZYAAOSwpY9jwPDs/s-l225.jpg';

              return (
                <ResultHorizontal
                  key={itemId}
                  condition={condition}
                  image={imageURI}
                  itemId={itemId}
                  price={price.value}
                  promoted={listing.topRatedBuyingExperience}
                  title={title}
                />
              );
            })}
          </ScrollView>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

SearchResult.propTypes = {
  // required
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  containerSticky: {
    backgroundColor: colors.n0
  },
  notch: {
    height: device.iPhoneNotch ? 46 : 16,
    width: '100%'
  },
  containerSearch: {
    flex: 1
  },
  input: {
    ...gStyle.pL2,
    backgroundColor: colors.n1,
    borderRadius: 20,
    color: colors.n7,
    fontFamily: fonts.msRegular,
    height: 40
  },
  containerIcon: {
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    right: 24
  }
});

export default SearchResult;
