import * as React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { gStyle } from '../constants';

// components
import Button from '../components/Button';

// app state
import Context from '../context';

function Selling({ navigation }) {
  // app state
  const { setLocationKey, setRadius, setSelectedDay } =
    React.useContext(Context);

  return (
    <View style={gStyle.containerBg}>
      <View style={gStyle.spacer4} />

      <View style={gStyle.pH2}>
        <Button
          onPress={() => navigation.navigate('OrderDetails1')}
          text="Order details"
        />

        <View style={gStyle.spacer2} />

        <Button onPress={() => navigation.navigate('Emails')} text="Emails" />

        <View style={gStyle.spacer2} />

        <Button
          onPress={() => navigation.navigate('DrawerFilterOld')}
          text="Extra Screens"
        />

        <View style={gStyle.spacer4} />

        <Button
          onPress={() => {
            // reset to original state
            setLocationKey(null);
            setRadius(100);
            setSelectedDay(null);
          }}
          text="Reset Prototype"
          type="secondary"
        />
      </View>
    </View>
  );
}

Selling.propTypes = {
  // required
  navigation: PropTypes.object.isRequired
};

/*
import MenuItemRadio from '../components/MenuItemRadio';

// app state
import Context from '../context';

const { Light } = Haptics.ImpactFeedbackStyle;

// app state
const { locale, setLocale, localeStrings } = React.useContext(Context);

// translations
const setLocaleText = localeStrings('setLocale', locale);

<Text style={styles.heading}>{setLocaleText}</Text>

<View style={gStyle.spacer2} />

<MenuItemRadio
  onPress={() => {
    // on iOS device, use Haptic feedback
    if (device.iOS) Haptics.impactAsync(Light);

    setLocale('en');
  }}
  selected={locale === 'en'}
  text="English"
/>
<MenuItemRadio
  onPress={() => {
    // on iOS device, use Haptic feedback
    if (device.iOS) Haptics.impactAsync(Light);

    setLocale('de');
  }}
  selected={locale === 'de'}
  text="Deutsch"
/>

const styles = StyleSheet.create({
  heading: {
    ...gStyle.mH2,
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 20
  }
});
*/

export default Selling;
