// app fonts preload
// /////////////////////////////////////////////////////////////////////////////

// add custom font requires here
const msBold = require('../assets/fonts/MarketSans-Bold.ttf');
const msRegular = require('../assets/fonts/MarketSans-Regular.ttf');

// then add them to the obj below & add them in the `constants/fonts.js`
export default [
  {
    msBold,
    msRegular
  }
];
