import * as React from 'react';
import PropTypes from 'prop-types';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import * as Haptics from 'expo-haptics';
import { colors, device, fonts, gStyle } from '../constants';

// components
import Button from '../components/Button';
import CardItemOverview from '../components/CardItemOverview';
import CardLocation from '../components/CardLocation';
import Divider from '../components/Divider';
import RadioCircle from '../components/RadioCircle';

// icons
import { SvgChevronDownSm, SvgChevronUpSm, SvgSort2 } from '../icons';

// data
import tireShops from '../data/tireShops.json';

// app state
import Context from '../context';

const { Light } = Haptics.ImpactFeedbackStyle;

function TireInstall({ navigation, route }) {
  // app state
  const cntx = React.useContext(Context);
  const { locale, localeStrings, locationKey, radius, setLocationKey } = cntx;

  // local state
  const [opened, setOpened] = React.useState(false);

  const { item, itemId } = route.params;

  // translations
  const confirmInstaller = localeStrings('confirmInstaller', locale);
  const sort = localeStrings('sort', locale);

  return (
    <React.Fragment>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
        <CardItemOverview item={item} />

        <View style={gStyle.spacer1} />

        <View style={gStyle.pH2}>
          <Text style={styles.heading}>Choose your installer</Text>

          <View style={gStyle.spacer1} />

          <Text style={styles.textMuted}>
            {`Installation within `}
            <Text style={styles.textUnderline}>{`${radius} mi of 98126`}</Text>
          </Text>
          <Text style={styles.textMuted}>
            We&apos;ll ship the tires directly to their service center.
          </Text>

          <View style={gStyle.spacer2} />

          <TouchableOpacity
            activeOpacity={gStyle.activeOpacity}
            onPress={() => {
              // on iOS device, use Haptic feedback
              if (device.iOS) Haptics.impactAsync(Light);

              setOpened(!opened);
            }}
            style={gStyle.flexRowAlignCenter}
          >
            <Text style={styles.textPara}>Installation details</Text>

            <View style={gStyle.spacer1W} />

            {opened === false && <SvgChevronDownSm size={12} />}
            {opened === true && <SvgChevronUpSm size={12} />}
          </TouchableOpacity>

          {opened && (
            <React.Fragment>
              <View style={gStyle.spacer1} />
              <Text style={styles.textMuted}>- Shipping to installer</Text>
              <Text style={styles.textMuted}>- Mounting and balancing</Text>
              <Text style={styles.textMuted}>
                - Rubber valve stem replacement
              </Text>
              <Text style={styles.textMuted}>
                - Tire Pressure Monitoring System (TPMS) reset; excludes new
                kits & sensors
              </Text>
              <Text style={styles.textMuted}>
                - Tire disposal and required environmental fees
              </Text>
              <Text style={styles.textMuted}>
                - Shop fees and applicable sales tax
              </Text>

              <View style={gStyle.spacer2} />

              <Text style={styles.textUnderline}>See more details</Text>
            </React.Fragment>
          )}

          <View style={gStyle.spacer2} />

          <View style={[gStyle.flexRowEnd, gStyle.mB1]}>
            <SvgSort2 />
            <Text style={styles.textSort}>{sort}</Text>
          </View>

          <Divider />

          <View style={gStyle.spacer2} />

          {Object.keys(tireShops).map((key) => {
            const shop = tireShops[key];
            const isSelected = locationKey === key;

            return (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => {
                  // on iOS device, use Haptic feedback
                  if (device.iOS) Haptics.impactAsync(Light);

                  // allow for toggle
                  const newValue = isSelected ? null : key;

                  setLocationKey(newValue);
                }}
                style={styles.selectBlock}
                key={shop.id}
              >
                <RadioCircle selected={isSelected} />

                <View style={gStyle.spacer2W} />

                <CardLocation {...shop} selected={isSelected} />
              </TouchableOpacity>
            );
          })}
        </View>
      </ScrollView>

      <View style={styles.contanerButtons}>
        <Button
          onPress={
            locationKey
              ? () =>
                  navigation.navigate('TireInstallSchedule', { item, itemId })
              : () => null
          }
          type={locationKey ? 'primary' : 'disabled'}
          text={confirmInstaller}
        />

        <View style={gStyle.spacer2} />

        <Button
          onPress={() => {
            navigation.goBack();

            setLocationKey(null);
          }}
          text="No, thanks"
          type="secondary"
        />

        <View style={gStyle.spacer2} />
      </View>
    </React.Fragment>
  );
}

TireInstall.propTypes = {
  // required
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flex1,
    backgroundColor: colors.n0
  },
  heading: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 16,
    lineHeight: 24
  },
  textMuted: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 18,
    maxWidth: 328
  },
  textUnderline: {
    color: colors.n7,
    textDecorationLine: 'underline'
  },
  textPara: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    lineHeight: 18
  },
  textSort: {
    ...gStyle.mLHalf,
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 12,
    lineHeight: 18
  },
  selectBlock: {
    ...gStyle.flexRow,
    ...gStyle.mB2,
    ...gStyle.pB2,
    borderBottomColor: colors.n2,
    borderBottomWidth: 1
  },
  contanerButtons: {
    ...gStyle.p2,
    backgroundColor: colors.n0
  }
});

export default TireInstall;
