import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View
} from 'react-native';
import { colors, device, gStyle, images } from '../constants';

function OrderDetails1({ navigation }) {
  // get height and keep aspect ratio
  const height = Math.ceil((1504 / 750) * device.width);

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={styles.container}
    >
      <View style={gStyle.spacer4} />
      <TouchableOpacity
        activeOpacity={1}
        onPress={() => navigation.navigate('OrderDetails2')}
      >
        <Image source={images.purchases1} style={[styles.image, { height }]} />
      </TouchableOpacity>
      <View style={gStyle.spacer4} />
    </ScrollView>
  );
}

OrderDetails1.propTypes = {
  // required
  navigation: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.n0
  },
  image: {
    backgroundColor: colors.n2,
    width: device.width
  }
});

export default OrderDetails1;
