import React from 'react';
import PropTypes from 'prop-types';
import { func } from '../constants';

// locale
import { strings } from '../locales/translate';

// app state
import Context from './index';

function AppState({ children }) {
  const [locale, setLocale] = React.useState('en');
  const [locationKey, setLocationKey] = React.useState(null);
  const [radius, setRadius] = React.useState(100);
  const [selectedDay, setSelectedDay] = React.useState(null);
  const [showInstallListings, setInstallListings] = React.useState(true);
  const [zip, setZip] = React.useState('98125');

  // delivery stuff
  const todaysDate = func.getTodayDate();
  const todaysDateMinus = '2023-04-18';
  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  const today = new Date(todaysDateMinus);
  const deliverStart = new Date(todaysDateMinus);
  deliverStart.setDate(today.getDate() + 6);
  const dS = deliverStart.toLocaleDateString('en-us', options);
  const deliverEnd = new Date(todaysDateMinus);
  deliverEnd.setDate(today.getDate() + 9);
  const dE = deliverEnd.toLocaleDateString('en-us', options);

  const stateValues = React.useMemo(
    () => ({
      deliveryStart: func.getDateString(deliverEnd),
      deliveryEndShort: dE,
      deliveryRange: `${dS} - ${dE}`,
      todaysDate: todaysDateMinus,
      todaysDateStart: todaysDate,
      locale,
      setLocale,
      localeStrings: strings,
      locationKey,
      setLocationKey,
      radius,
      setRadius,
      selectedDay,
      setSelectedDay,
      showInstallListings,
      setInstallListings,
      zip,
      setZip
    }),
    [locale, locationKey, radius, selectedDay, showInstallListings, zip]
  );

  return <Context.Provider value={stateValues}>{children}</Context.Provider>;
}

AppState.propTypes = {
  // required
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default AppState;
