// local images
// /////////////////////////////////////////////////////////////////////////////

export default {
  email1: require('../assets/images/email-1.jpg'),
  email2: require('../assets/images/email-2.jpg'),
  email3: require('../assets/images/email-3.jpg'),
  email4: require('../assets/images/email-4.jpg'),
  map: require('../assets/images/map.jpg'),
  orderdetails1: require('../assets/images/order-details-1.jpg'),
  purchases1: require('../assets/images/purchases-1.jpg'),
  simpletire: require('../assets/images/simpletire.png')
};
