export default {
  // eBay Design System colors
  // https://playbook.corp.ebay.com/PlaybookApp#/building-blocks/color/our-colors

  // red
  r1: '#ffd1dd',
  r2: '#ffa2b6',
  r3: '#ff6383',
  r4: '#e0103a',
  r7: '#680226',

  // orange
  o2: '#feb786',
  o3: '#ff8559',
  o5: '#db3c07',
  o7: '#5c1b05',

  // yellow
  y1: '#fcf2bd',
  y2: '#f7e376',
  y3: '#fbcd25',
  y7: '#592e13',

  // lime
  l2: '#e9f577',
  l3: '#c9e43b',
  l4: '#accf02',
  l7: '#364f03',

  // green
  g2: '#9ef4a6',
  g3: '#5ee471',
  g4: '#28a443',
  g6: '#05823f',
  g7: '#07522c',

  // teal
  t1: '#c2f2ef',
  t2: '#71e3e2',
  t3: '#1dcbca',
  t5: '#01718f',
  t7: '#003147',

  // blue
  b1: '#c5e5fb',
  b2: '#93c9ff',
  b4: '#3665f3',
  b6: '#2b0eaf',
  b7: '#121258',

  // magenta
  m1: '#fad8f0',
  m2: '#f5a0d9',
  m3: '#d11da4',
  m4: '#a60d8a',
  m5: '#82187c',
  m7: '#500750',

  // grey / neutral
  n0: '#ffffff',
  n1: '#f7f7f7',
  n2: '#e5e5e5',
  n3: '#c7c7c7',
  n4: '#a2a2a2',
  n5: '#707070',
  n6: '#414141',
  n7: '#111820',

  // https://www.figma.com/file/zEBdEhbonrBOGzZ0fXzWvM/eBay-Design-System?node-id=0%3A7267
  imageOverlay: 'rgba(0, 0, 0, 0.05)',
  backdrop: 'rgba(0, 0, 0, 0.3)'
};
