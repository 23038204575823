import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// icons
import { SvgChevronRight } from '../icons';

function MenuItemFilter({ onPress, text, value }) {
  return (
    <TouchableOpacity
      activeOpacity={onPress === null ? 1 : gStyle.activeOpacity}
      onPress={onPress === null ? () => null : onPress}
      style={styles.container}
    >
      <Text style={styles.label}>{text}</Text>

      <View style={styles.contentRight}>
        {value && (
          <Text style={styles.value} numberOfLines={1}>
            {value}
          </Text>
        )}

        <SvgChevronRight size={16} />
      </View>
    </TouchableOpacity>
  );
}

MenuItemFilter.defaultProps = {
  onPress: null,
  value: null
};

MenuItemFilter.propTypes = {
  // required
  text: PropTypes.string.isRequired,

  // optional
  onPress: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flexRowSpace,
    ...gStyle.mH2,
    ...gStyle.pV2,
    borderBottomColor: colors.n2,
    borderBottomWidth: 1
  },
  label: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  contentRight: {
    ...gStyle.flexRowAlignCenter,
    flex: 3,
    justifyContent: 'flex-end'
  },
  value: {
    ...gStyle.mRHalf,
    color: colors.n5,
    flex: 1,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    textAlign: 'right'
  }
});

export default React.memo(MenuItemFilter);
