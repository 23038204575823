import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// components
import Button from '../components/Button';

// icons
import SvgClose from '../icons/Svg.Close';

// app state
import Context from '../context';

function ModalChangeInstaller({ route, navigation }) {
  // app state
  const { setLocationKey, setSelectedDay } = React.useContext(Context);

  const { item, itemId } = route.params;

  // animation
  const animationDuration = 400;
  const bottomDefault = -600;
  const bottom = React.useRef(new Animated.Value(bottomDefault)).current;

  // animate out, then navigate back
  const onClose = () => {
    Animated.timing(bottom, {
      duration: animationDuration,
      toValue: bottomDefault,
      useNativeDriver: false
    }).start();

    setTimeout(() => navigation.goBack(), 200);
  };

  // animate in, on modal open
  React.useEffect(() => {
    // mount
    Animated.timing(bottom, {
      duration: animationDuration,
      toValue: 0,
      useNativeDriver: false
    }).start();

    return () => {
      // unmount
    };
  }, []);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        activeOpacity={1}
        onPress={onClose}
        style={styles.backdrop}
      />

      <Animated.View style={[styles.containerModal, { bottom }]}>
        <View style={gStyle.flexRowSpace}>
          <Text style={styles.header}>Change the installer?</Text>

          <TouchableOpacity
            activeOpacity={gStyle.activeOpacity}
            hitSlop={{ top: 10, left: 10, bottom: 10, right: 10 }}
            onPress={onClose}
            style={styles.containerClose}
          >
            <SvgClose size={18} />
          </TouchableOpacity>
        </View>

        <View style={gStyle.spacer1} />

        <Text style={styles.text}>
          The current installer will not be saved and you&apos;ll need to select
          a new installer.
        </Text>

        <View style={gStyle.spacer1} />

        <Button
          onPress={() => {
            navigation.goBack();
            navigation.goBack();

            navigation.navigate('TireInstall', { item, itemId });

            setLocationKey(null);
            setSelectedDay(null);
          }}
          text="Change installer"
        />

        <View style={gStyle.spacer1} />

        <Button onPress={onClose} text="Cancel" type="secondary" />
      </Animated.View>
    </View>
  );
}

ModalChangeInstaller.propTypes = {
  // required
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flex1
  },
  backdrop: {
    ...gStyle.flex1,
    backgroundColor: colors.backdrop,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  containerClose: {
    alignItems: 'center',
    backgroundColor: colors.n1,
    borderRadius: 16,
    height: 32,
    justifyContent: 'center',
    width: 32
  },
  containerModal: {
    ...gStyle.p2,
    backgroundColor: colors.n0,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingBottom: 24,
    position: 'absolute',
    width: '100%'
  },
  header: {
    fontFamily: fonts.msBold,
    fontSize: 16
  },
  text: {
    fontFamily: fonts.msRegular,
    fontSize: 16,
    lineHeight: 24,
    maxWidth: 320
  }
});

export default ModalChangeInstaller;
