import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgArrowLeft({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M21 11H5.41l6.3-6.29a1.003 1.003 0 10-1.42-1.42l-8 8a1.001 1.001 0 000 1.42l8 8a1.001 1.001 0 001.42 0 1.001 1.001 0 000-1.42L5.41 13H21a1 1 0 000-2z"
        fill={fill}
      />
    </Svg>
  );
}

SvgArrowLeft.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgArrowLeft.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgArrowLeft);
