import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgTriangle({ fill }) {
  return (
    <Svg height="6" viewBox="0 0 12 6" width="12">
      <Path
        fill={fill}
        fillRule="evenodd"
        d="m6 0 6 6H0l6-6Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}

SvgTriangle.defaultProps = {
  fill: '#ffffff'
};

SvgTriangle.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default React.memo(SvgTriangle);
