import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgPlus({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M21 11h-8V3a1 1 0 00-2 0v8H3a1 1 0 000 2h8v8a1 1 0 002 0v-8h8a1 1 0 000-2z"
        fill={fill}
      />
    </Svg>
  );
}

SvgPlus.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgPlus.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgPlus);
