import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgChevronRight({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M8 21a.999.999 0 01-.71-1.71l7.3-7.29-7.3-7.29a1.003 1.003 0 111.42-1.42l8 8a1.001 1.001 0 010 1.42l-8 8A.999.999 0 018 21z"
        fill={fill}
      />
    </Svg>
  );
}

SvgChevronRight.defaultProps = {
  fill: colors.n7,
  size: 24
};

SvgChevronRight.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgChevronRight);
