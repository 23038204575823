import * as React from 'react';
import PropTypes from 'prop-types';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

function Button({ iconLeft, iconRight, onPress, styleExtend, text, type }) {
  // default is primary
  let baseStyle = styles.primary;
  let color = colors.n0;
  let baseTextStyle = [styles.primaryText, { color }];
  let pressedStyle = styles.primaryPressed;
  let pressedColor = colors.n0;

  let disabled = false;

  // type styling
  if (type === 'secondary') {
    baseStyle = styles.secondary;
    color = colors.b4;
    baseTextStyle = [styles.secondaryText, { color }];
    pressedStyle = styles.secondaryPressed;
    pressedColor = colors.b6;
  }

  // type styling
  if (type === 'disabled') {
    disabled = true;
    baseStyle = styles.disabled;
    color = colors.n0;
    baseTextStyle = [styles.disabledText, { color }];
    pressedStyle = styles.disabledPressed;
    pressedColor = colors.n0;
  }

  const IconLeft = iconLeft || null;
  const IconRight = iconRight || null;

  const style = [baseStyle, styleExtend];

  return (
    <Pressable
      activeOpacity={disabled ? 1 : gStyle.activeOpacity}
      onPress={onPress}
      style={({ pressed }) => [style, pressed ? pressedStyle : {}]}
    >
      {({ pressed }) => (
        <View style={styles.containerContent}>
          {IconLeft && (
            <View style={gStyle.mR1}>
              <IconLeft fill={pressed ? pressedColor : color} size={16} />
            </View>
          )}

          <Text style={[baseTextStyle, pressed ? { color: pressedColor } : {}]}>
            {text}
          </Text>

          {IconRight && (
            <View style={gStyle.mL1}>
              <IconRight fill={pressed ? pressedColor : color} size={16} />
            </View>
          )}
        </View>
      )}
    </Pressable>
  );
}

Button.defaultProps = {
  iconLeft: null,
  iconRight: null,
  onPress: () => null,
  styleExtend: {},
  type: 'primary'
};

Button.propTypes = {
  // required
  text: PropTypes.string.isRequired,

  // optional
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  onPress: PropTypes.func,
  styleExtend: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  type: PropTypes.oneOf(['primary', 'secondary', 'disabled'])
};

const styles = StyleSheet.create({
  primary: {
    alignItems: 'center',
    backgroundColor: colors.b4,
    borderRadius: 24,
    height: 48,
    justifyContent: 'center'
  },
  primaryPressed: {
    backgroundColor: colors.b6
  },
  primaryText: {
    fontFamily: fonts.msBold,
    fontSize: 16
  },
  secondary: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderColor: colors.b4,
    borderRadius: 24,
    borderWidth: 1,
    height: 48,
    justifyContent: 'center'
  },
  secondaryPressed: {
    borderColor: colors.b6
  },
  secondaryText: {
    fontFamily: fonts.msRegular,
    fontSize: 16
  },
  disabled: {
    alignItems: 'center',
    backgroundColor: colors.n3,
    borderColor: colors.n3,
    borderRadius: 24,
    borderWidth: 1,
    height: 48,
    justifyContent: 'center'
  },
  disabledPressed: {
    borderColor: colors.n3
  },
  disabledText: {
    fontFamily: fonts.msBold,
    fontSize: 16
  },
  containerContent: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center'
  }
});

export default Button;
