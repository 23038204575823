import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgThumbsDown({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M.005 11.56a3.856 3.856 0 01.779-2.53 2.92 2.92 0 00.151-.229 5.573 5.573 0 01.037-4.19A4.046 4.046 0 014.805 1h4.749a10.492 10.492 0 014.16.854l3.491 1.496V2a1 1 0 011-1h3.88a.96.96 0 01.96.96V15.4a.96.96 0 01-.96.96h-3.84a.96.96 0 01-.96-.96v-.891a2.456 2.456 0 00-2.021 1.32 5.88 5.88 0 01-1.842 2.069c-.696.544-.937.762-.937 1.342v2.88a.96.96 0 01-.96.96h-.96a3.844 3.844 0 01-3.84-3.84v-1.945c0-.723.117-1.44.345-2.126l.243-.729H2.885a2.883 2.883 0 01-2.88-2.88zm21.12-8.64h-1.92v11.52h1.92V2.92zM2.885 8.68a2.354 2.354 0 01-.534 1.458 2.02 2.02 0 00-.426 1.422c.001.53.43.959.96.96h5.76a.958.958 0 01.911 1.264l-.664 1.993a4.775 4.775 0 00-.247 1.518v1.945a1.922 1.922 0 001.92 1.92v-1.92a3.41 3.41 0 011.676-2.855 4.104 4.104 0 001.305-1.414 4.435 4.435 0 013.739-2.393V5.473l-4.327-1.855a8.6 8.6 0 00-3.404-.698H4.805a2.121 2.121 0 00-1.92 1.92.953.953 0 01-.101.429 3.694 3.694 0 000 2.982c.067.133.101.28.101.429z"
        fill={fill}
      />
    </Svg>
  );
}

SvgThumbsDown.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgThumbsDown.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgThumbsDown);
