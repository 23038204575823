import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgTire({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 40 40" width={size}>
      <Path
        clipRule="evenodd"
        fill={fill}
        fillRule="evenodd"
        d="M4.795 20c0 8.383 6.82 15.203 15.202 15.203 8.384 0 15.202-6.82 15.202-15.202 0-8.383-6.818-15.202-15.202-15.202-8.383 0-15.202 6.819-15.202 15.202ZM0 20C0 8.955 8.954 0 20 0s20 8.955 20 20c0 11.046-8.954 20-20 20S0 31.046 0 20Zm25.733 3.768c-1.548 2.13-1.357 5.016.089 6.995.108.147.333.19.49.1a12.566 12.566 0 0 0 6.083-8.828.389.389 0 0 0-.268-.424c-2.268-.618-4.91.113-6.394 2.157Zm6.534-6.476a12.583 12.583 0 0 0-6.506-8.455.38.38 0 0 0-.482.126c-1.237 1.96-1.328 4.652.136 6.666 1.53 2.107 4.293 2.822 6.605 2.097a.381.381 0 0 0 .247-.433Zm-15.263 2.684a2.994 2.994 0 1 0 5.989 0 2.994 2.994 0 0 0-5.99 0Zm-5.513 8.81a.388.388 0 0 0 .053.5 12.512 12.512 0 0 0 10.255 3.144.39.39 0 0 0 .323-.383c-.096-2.364-1.617-4.675-4.035-5.46-2.486-.808-5.154.243-6.595 2.2ZM7.44 20c0 1.969.457 3.83 1.264 5.488.08.164.29.25.46.183 2.163-.853 3.829-2.986 3.829-5.483 0-2.56-1.751-4.736-3.992-5.544a.376.376 0 0 0-.454.197A12.513 12.513 0 0 0 7.439 20ZM21.611 7.88a.375.375 0 0 0-.327-.37 12.512 12.512 0 0 0-10.05 3.501.373.373 0 0 0-.03.493c1.485 1.77 4.008 2.68 6.37 1.913 2.443-.795 3.973-3.147 4.037-5.538ZM5.79 20c0-7.835 6.376-14.21 14.212-14.21 7.835 0 14.21 6.375 14.21 14.21 0 7.835-6.375 14.21-14.21 14.21-7.836 0-14.212-6.374-14.212-14.21Z"
      />
    </Svg>
  );
}

SvgTire.defaultProps = {
  fill: '#000000',
  size: 40
};

SvgTire.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgTire);
