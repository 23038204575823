import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgHeartOutline({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M21.262 3.857a5.808 5.808 0 00-8.524 0l-.74.778-.74-.778a5.808 5.808 0 00-8.525 0 6.508 6.508 0 000 8.844l8.544 8.984a1.002 1.002 0 001.45 0l8.545-8.984a6.508 6.508 0 00-.01-8.844zm-1.451 7.467l-7.813 8.195-7.814-8.185a4.494 4.494 0 010-6.1 3.815 3.815 0 015.622 0l1.461 1.548a1.002 1.002 0 001.45 0l1.471-1.548a3.815 3.815 0 015.623 0 4.494 4.494 0 010 6.1v-.01z"
        fill={fill}
      />
    </Svg>
  );
}

SvgHeartOutline.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgHeartOutline.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgHeartOutline);
