import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import {
  CardStyleInterpolators,
  createStackNavigator,
  TransitionPresets
} from '@react-navigation/stack';
import { colors, fonts, gStyle } from '../constants';

// tab navigation
import TabNavigation from './TabNavigation';

// screens
import TireInstall from '../screens/TireInstall';
import TireInstallSchedule from '../screens/TireInstallSchedule';
import ModalMap from '../screens/ModalMap';
import Checkout from '../screens/Checkout';
import CheckoutSuccess from '../screens/CheckoutSuccess';
import ModalChangeInstaller from '../screens/ModalChangeInstaller';

import Emails from '../screens/Emails';
import Email1 from '../screens/Email1';
import OrderDetails1 from '../screens/OrderDetails1';
import OrderDetails2 from '../screens/OrderDetails2';

import DrawerFilterOld from '../screens/DrawerFilterOld';

// icons
import { SvgCart, SvgChevronLeft, SvgClose } from '../icons';

// app state
import Context from '../context';

const Stack = createStackNavigator();

const forFade = ({ current }) => ({
  cardStyle: {
    opacity: current.progress
  }
});

function StackMain() {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  // translations
  const tireInstallText = localeStrings('tireInstall', locale);
  const map = localeStrings('map', locale);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="main"
        component={TabNavigation}
        options={{ headerShown: false }}
      />

      <Stack.Group
        screenOptions={{
          ...TransitionPresets.SlideFromRightIOS,
          presentation: 'modal'
        }}
      >
        <Stack.Screen
          name="TireInstall"
          component={TireInstall}
          options={({ navigation, route }) => {
            const { item, itemId } = route.params;

            return {
              headerLeft: () => (
                <TouchableOpacity
                  activeOpacity={gStyle.activeOpacity}
                  onPress={() => navigation.goBack(null)}
                  style={[gStyle.headingIcon, gStyle.mL1]}
                >
                  <SvgChevronLeft size={20} />
                </TouchableOpacity>
              ),
              headerRight: () => (
                <TouchableOpacity
                  activeOpacity={gStyle.activeOpacity}
                  hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
                  onPress={() =>
                    navigation.navigate('ModalMap', { item, itemId })
                  }
                  style={gStyle.mR2}
                >
                  <Text style={styles.mapText}>{map}</Text>
                </TouchableOpacity>
              ),
              headerStyle: [
                gStyle.navHeaderContainerStyle,
                { borderBottomColor: 'transparent', shadowColor: 'transparent' }
              ],
              headerTitleAlign: 'center',
              headerTitleStyle: { fontFamily: fonts.msBold },
              title: tireInstallText
            };
          }}
        />
        <Stack.Screen
          name="TireInstallSchedule"
          component={TireInstallSchedule}
          options={({ navigation }) => ({
            headerLeft: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => navigation.goBack(null)}
                style={[gStyle.headingIcon, gStyle.mL1]}
              >
                <SvgChevronLeft size={20} />
              </TouchableOpacity>
            ),
            headerStyle: [
              gStyle.navHeaderContainerStyle,
              { borderBottomColor: 'transparent', shadowColor: 'transparent' }
            ],
            headerTitleAlign: 'center',
            headerTitleStyle: { fontFamily: fonts.msBold },
            title: tireInstallText
          })}
        />
        <Stack.Screen
          name="Checkout"
          component={Checkout}
          options={({ navigation }) => ({
            headerLeft: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => navigation.goBack(null)}
                style={[gStyle.headingIcon, gStyle.mL1]}
              >
                <SvgChevronLeft size={20} />
              </TouchableOpacity>
            ),
            headerStyle: [
              gStyle.navHeaderContainerStyle,
              { borderBottomColor: 'transparent', shadowColor: 'transparent' }
            ],
            headerTitleAlign: 'center',
            headerTitleStyle: { fontFamily: fonts.msBold },
            title: 'Checkout'
          })}
        />
        <Stack.Screen
          name="CheckoutSuccess"
          component={CheckoutSuccess}
          options={({ navigation }) => ({
            headerLeft: null,
            headerRight: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => navigation.navigate('StackSearch')}
                style={[gStyle.headingIcon, gStyle.mR2]}
              >
                <SvgClose size={20} />
              </TouchableOpacity>
            ),
            headerStyle: [
              gStyle.navHeaderContainerStyle,
              { borderBottomColor: 'transparent', shadowColor: 'transparent' }
            ],
            headerTitleAlign: 'center',
            headerTitleStyle: { fontFamily: fonts.msBold },
            title: 'Checkout success'
          })}
        />
        <Stack.Screen
          name="DrawerFilterOld"
          component={DrawerFilterOld}
          options={{ headerShown: false }}
        />
      </Stack.Group>

      <Stack.Group
        screenOptions={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          presentation: 'modal'
        }}
      >
        <Stack.Screen
          name="ModalMap"
          component={ModalMap}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="ModalChangeInstaller"
          component={ModalChangeInstaller}
          options={{
            cardStyleInterpolator: forFade,
            gestureEnabled: false,
            headerShown: false,
            presentation: 'transparentModal'
          }}
        />

        <Stack.Screen
          name="Emails"
          component={Emails}
          options={({ navigation }) => ({
            headerLeft: null,
            headerRight: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => navigation.goBack()}
                style={[gStyle.headingIcon, gStyle.mR2]}
              >
                <SvgClose size={20} />
              </TouchableOpacity>
            ),
            headerStyle: [
              gStyle.navHeaderContainerStyle,
              { borderBottomColor: 'transparent', shadowColor: 'transparent' }
            ],
            headerTitleAlign: 'center',
            headerTitleStyle: { fontFamily: fonts.msBold },
            title: null
          })}
        />

        <Stack.Screen
          name="Email1"
          component={Email1}
          options={({ navigation }) => ({
            ...TransitionPresets.SlideFromRightIOS,
            headerLeft: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => navigation.goBack(null)}
                style={[gStyle.headingIcon, gStyle.mL1]}
              >
                <SvgChevronLeft size={20} />
              </TouchableOpacity>
            ),
            headerStyle: [
              gStyle.navHeaderContainerStyle,
              { borderBottomColor: 'transparent', shadowColor: 'transparent' }
            ],
            headerTitleAlign: 'center',
            headerTitleStyle: { fontFamily: fonts.msBold },
            title: null
          })}
        />

        <Stack.Screen
          name="OrderDetails1"
          component={OrderDetails1}
          options={({ navigation }) => ({
            headerLeft: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => navigation.goBack(null)}
                style={[gStyle.headingIcon, gStyle.mL1]}
              >
                <SvgChevronLeft size={20} />
              </TouchableOpacity>
            ),
            headerRight: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => null}
                style={[gStyle.headingIcon, gStyle.mR2]}
              >
                <SvgCart size={20} />
              </TouchableOpacity>
            ),
            headerStyle: [
              gStyle.navHeaderContainerStyle,
              { borderBottomColor: 'transparent', shadowColor: 'transparent' }
            ],
            headerTitleAlign: 'center',
            headerTitleStyle: { fontFamily: fonts.msBold },
            title: 'Purchases'
          })}
        />

        <Stack.Screen
          name="OrderDetails2"
          component={OrderDetails2}
          options={({ navigation }) => ({
            ...TransitionPresets.SlideFromRightIOS,
            headerLeft: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => navigation.goBack(null)}
                style={[gStyle.headingIcon, gStyle.mL1]}
              >
                <SvgChevronLeft size={20} />
              </TouchableOpacity>
            ),
            headerRight: () => (
              <TouchableOpacity
                activeOpacity={gStyle.activeOpacity}
                onPress={() => null}
                style={[gStyle.headingIcon, gStyle.mR2]}
              >
                <SvgCart size={20} />
              </TouchableOpacity>
            ),
            headerStyle: [
              gStyle.navHeaderContainerStyle,
              { borderBottomColor: 'transparent', shadowColor: 'transparent' }
            ],
            headerTitleAlign: 'center',
            headerTitleStyle: { fontFamily: fonts.msBold },
            title: 'Order details'
          })}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}

const styles = StyleSheet.create({
  mapText: {
    color: colors.b4,
    fontFamily: fonts.msRegular,
    fontSize: 15,
    lineHeight: 20
  }
});

export default StackMain;
