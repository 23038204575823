import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// components
import QuickFilter from './QuickFilter';

// icons
import { SvgChevronRight } from '../icons';

function MenuItemOptions({ text, noDivider, onPress }) {
  const borderBottomWidth = noDivider ? 0 : 1;

  return (
    <TouchableOpacity
      activeOpacity={gStyle.activeOpacity}
      onPress={onPress}
      style={[styles.container, { borderBottomWidth }]}
    >
      <View style={gStyle.flexRowSpace}>
        <Text style={styles.text}>{text}</Text>

        <View style={gStyle.flexRowAlignCenter}>
          <SvgChevronRight size={12} />
        </View>
      </View>

      <View style={gStyle.spacer2} />

      <View style={gStyle.flexRowAlignCenter}>
        <QuickFilter selected text="Show all" />
        <View style={gStyle.spacer1W} />
        <QuickFilter text="Front" />
        <View style={gStyle.spacer1W} />
        <QuickFilter text="Rear" />
      </View>
    </TouchableOpacity>
  );
}

MenuItemOptions.defaultProps = {
  noDivider: false,
  onPress: () => null
};

MenuItemOptions.propTypes = {
  // required
  text: PropTypes.string.isRequired,

  // optional
  noDivider: PropTypes.bool,
  onPress: PropTypes.func
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.mH2,
    ...gStyle.pV2,
    borderBottomColor: colors.n2
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  }
});

export default React.memo(MenuItemOptions);
