import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgEyeShow({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M12 9a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0-2a4.5 4.5 0 104.5 4.5A4.49 4.49 0 0012 7z"
        fill={fill}
      />
      <Path
        d="M12 6c4.35 0 7.43 4 8.64 5.93C19.4 13.61 16.36 17 12 17s-7.4-3.4-8.64-5.08C4.56 10 7.63 6 12 6zm0-2C5 4 1 12 1 12s4 7 11 7 11-7 11-7-4-8-11-8z"
        fill={fill}
      />
    </Svg>
  );
}

SvgEyeShow.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgEyeShow.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgEyeShow);
