import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgShare({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M11.293 1.293a1.002 1.002 0 011.414 0l4 4a1 1 0 01-1.414 1.414L13 4.414V15a1 1 0 11-2 0V4.414L8.707 6.707a1 1 0 01-1.414-1.414l4-4z"
        fill={fill}
      />
      <Path
        d="M19 10v11H5V10h3a1 1 0 100-2H4a1 1 0 00-1 1v13a1 1 0 001 1h16a1 1 0 001-1V9a1 1 0 00-1-1h-4a1 1 0 100 2h3z"
        fill={fill}
      />
    </Svg>
  );
}

SvgShare.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgShare.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgShare);
