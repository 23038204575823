import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgSend({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M1 3a1.002 1.002 0 00-.707 1.707l6.747 6.748.965 9.645a1 1 0 001.767.536l14-17A1 1 0 0023 3H1zm7.212 6.798L3.414 5H20.88L9.756 18.509l-.69-6.903 6.857-3.2a1 1 0 10-.846-1.812L8.212 9.798z"
        fill={fill}
      />
    </Svg>
  );
}

SvgSend.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgSend.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgSend);
