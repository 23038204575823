import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { colors } from '../constants';

function Divider() {
  return <View style={styles.divider} />;
}

const styles = StyleSheet.create({
  divider: {
    backgroundColor: colors.n2,
    height: 1,
    width: '100%'
  }
});

export default React.memo(Divider);
