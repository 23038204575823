import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgStarFilled({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M22.92 8.62A1 1 0 0022 8h-6.34l-2.74-6.39a1 1 0 00-1.84 0L8.34 8H2a.999.999 0 00-.71 1.71l4.6 4.6L4 21.76a.999.999 0 001.55 1.06L12 18.23l6.42 4.58A1 1 0 0020 21.76l-1.86-7.45 4.6-4.6c.273-.294.344-.723.18-1.09z"
        fill={fill}
      />
    </Svg>
  );
}

SvgStarFilled.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgStarFilled.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgStarFilled);
