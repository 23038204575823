import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgCameraSm({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 16 16" width={size}>
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M8 5.333A3.333 3.333 0 1 0 8 12a3.333 3.333 0 0 0 0-6.667ZM6 8.668a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z"
        clipRule="evenodd"
      />
      <Path
        fill={fill}
        fillRule="evenodd"
        d="M6.294 1.333a2 2 0 0 0-1.897 1.368l-.21.632H2a2 2 0 0 0-2 2v7.334a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V5.334a2 2 0 0 0-2-2h-2.186l-.211-.633a2 2 0 0 0-1.897-1.368H6.294Zm-.632 1.79a.667.667 0 0 1 .632-.456h3.412c.287 0 .541.183.632.456l.363 1.088c.09.272.345.456.632.456H14c.368 0 .667.298.667.667v7.333a.667.667 0 0 1-.667.667H2a.667.667 0 0 1-.667-.667V5.334c0-.369.299-.667.667-.667h2.667a.667.667 0 0 0 .632-.456l.363-1.088Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}

SvgCameraSm.defaultProps = {
  fill: '#000000',
  size: 16
};

SvgCameraSm.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgCameraSm);
