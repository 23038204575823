import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// icons
import { SvgTire } from '../icons';

// app state
import Context from '../context';

function CardTire() {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  // translations
  const rim = localeStrings('rim', locale);
  const tire = localeStrings('tire', locale);
  const change = localeStrings('change', locale);

  return (
    <View style={styles.container}>
      <View style={gStyle.flexRowAlignCenter}>
        <View style={styles.containerIcon}>
          <SvgTire />
        </View>

        <View style={styles.containerTextLeft}>
          <Text style={styles.textBold}>
            {rim} <Text style={styles.text}>18&quot;</Text>
          </Text>
          <View style={gStyle.spacerHalf} />
          <Text style={styles.textBold}>
            {tire} <Text style={styles.text}>225/40-18</Text>
          </Text>
        </View>
      </View>

      <View>
        <Text style={styles.textChange}>{change}</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...gStyle.flexRow,
    ...gStyle.p2,
    backgroundColor: colors.n1,
    justifyContent: 'space-between'
  },
  containerIcon: {
    ...gStyle.mR2,
    alignItems: 'center',
    backgroundColor: colors.n2,
    borderRadius: 8,
    height: 64,
    justifyContent: 'center',
    width: 64
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  textBold: {
    fontFamily: fonts.msBold
  },
  textChange: {
    color: colors.b4,
    fontFamily: fonts.msBold,
    fontSize: 12
  }
});

export default React.memo(CardTire);
