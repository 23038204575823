import * as React from 'react';
import { Text, View } from 'react-native';
import { fonts, gStyle } from '../constants';

function Home() {
  return (
    <View style={[gStyle.containerBg, gStyle.flexCenter]}>
      <Text style={{ fontFamily: fonts.msRegular }}>Home Screen</Text>
    </View>
  );
}

export default Home;
