import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgChevronDownSm({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 16 16" width={size}>
      <Path
        d="M14.71 5.29a1 1 0 00-1.41 0L8 10.59l-5.29-5.3a1.004 1.004 0 00-1.42 1.42l6 6a1 1 0 001.41 0l6-6a1 1 0 00.01-1.42z"
        fill={fill}
      />
    </Svg>
  );
}

SvgChevronDownSm.defaultProps = {
  fill: colors.n7,
  size: 16
};

SvgChevronDownSm.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgChevronDownSm);
