import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgHome({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M2.34 9.25l9-8a.999.999 0 011.33 0l9 8A1 1 0 0122 10v12a1 1 0 01-1 1h-6a1 1 0 01-1-1v-7h-4v7a1 1 0 01-1 1H3a1 1 0 01-1-1V10c0-.287.124-.56.34-.75zM20 10.45l-8-7.11-8 7.11V21h4v-7a1 1 0 011-1h6a1 1 0 011 1v7h4V10.45z"
        fill={fill}
      />
    </Svg>
  );
}

SvgHome.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgHome.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgHome);
