import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgChevronLeft({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M16 21a1.003 1.003 0 01-.71-.29l-8-8a1.001 1.001 0 010-1.42l8-8a1.004 1.004 0 011.42 1.42L9.41 12l7.3 7.29A1.001 1.001 0 0116 21z"
        fill={fill}
      />
    </Svg>
  );
}

SvgChevronLeft.defaultProps = {
  fill: colors.n7,
  size: 24
};

SvgChevronLeft.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgChevronLeft);
