// app fonts
// /////////////////////////////////////////////////////////////////////////////

export default {
  msBold: 'msBold',
  msRegular: 'msRegular',
  sfProTextThin: 'sfProTextThin',
  sfProTextMedium: 'sfProTextMedium',
  sfProTextRegular: 'sfProTextRegular',
  sfProTextSemibold: 'sfProTextSemibold'
};
