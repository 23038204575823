import * as React from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { colors, device, gStyle, images } from '../constants';

function OrderDetails2() {
  // get height and keep aspect ratio
  const height = Math.ceil((3326 / 750) * device.width);

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      <View style={gStyle.spacer4} />
      <Image source={images.orderdetails1} style={[styles.image, { height }]} />
      <View style={gStyle.spacer4} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.n0
  },
  image: {
    backgroundColor: colors.n2,
    width: device.width
  }
});

export default OrderDetails2;
