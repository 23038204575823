import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgUser({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M12 0a6.002 6.002 0 00-6 6c0 1.808.802 5.424 2.066 6.524 0 0-3.345 1.388-4.393 1.982C1.901 15.511.998 17.629 1 19.728v3.226C1 23.532 1.447 24 2 24h20c.552 0 1-.468 1-1.046v-3.226c.001-2.099-.902-4.217-2.674-5.222-1.048-.594-4.393-1.982-4.393-1.982C17.197 11.424 18 7.808 18 6a6 6 0 00-6-6zm0 2c2.205 0 4 1.794 4 4 0 1.747-.836 4.523-1.38 5.016a2 2 0 00.546 3.355c1.25.519 3.488 1.486 4.174 1.875 1.01.572 1.661 1.938 1.66 3.482V22H3v-2.274c-.002-1.542.65-2.908 1.66-3.48.685-.389 2.923-1.356 4.173-1.875a2.004 2.004 0 001.19-1.519 2.032 2.032 0 00-.643-1.836C8.834 10.523 8 7.747 8 6c0-2.206 1.794-4 4-4z"
        fill={fill}
      />
    </Svg>
  );
}

SvgUser.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgUser.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgUser);
