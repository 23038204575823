import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgLogo({ fill, width }) {
  // allow for override of brand colors for single coloring
  const fillArray = fill
    ? new Array(4).fill(fill)
    : ['#f5af02', '#e53238', '#0064d2', '#86b817'];

  // get height and keep aspect ratio
  const height = Math.ceil((28 / 69) * width);

  return (
    <Svg height={height} viewBox="0 0 69 28" width={width}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.393 19.19c0 1.019.03 2.024.117 2.93h-3.216a19.31 19.31 0 01-.117-2.205c-1.74 2.164-3.808 2.786-6.677 2.786-4.256 0-6.534-2.277-6.534-4.911 0-3.815 3.099-5.16 8.48-5.285 1.47-.034 3.123-.04 4.49-.04v-.372c0-2.554-1.618-3.604-4.422-3.604-2.08 0-3.614.872-3.772 2.378h-3.633c.384-3.756 4.282-4.706 7.71-4.706 4.106 0 7.574 1.479 7.574 5.876v7.153zm-7.71-4.338c-3.136.104-5.084.677-5.084 2.767 0 1.354 1.066 2.822 3.772 2.822 3.628 0 5.564-2.002 5.564-5.286v-.361c-1.272 0-2.84.011-4.253.058z"
        fill={fillArray[0]}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.521 15.317c.187 3.177 2.351 5.056 5.327 5.056 2.062 0 3.896-.85 4.51-2.7h3.566c-.694 3.754-4.633 5.028-8.025 5.028C2.733 22.701 0 19.263 0 14.626c0-5.104 2.827-8.465 8.955-8.465 4.879 0 8.453 2.585 8.453 8.226v.93H3.52zm10.254-2.328c-.134-3.275-2.469-4.5-4.964-4.5-2.69 0-4.839 1.378-5.215 4.5h10.179z"
        fill={fillArray[1]}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.403 0h3.457v8.793c1.694-2.044 4.029-2.637 6.326-2.637 3.853 0 8.132 2.632 8.132 8.315 0 4.759-3.403 8.225-8.196 8.225-2.508 0-4.87-.911-6.326-2.716 0 .72-.04 1.447-.118 2.135h-3.393c.06-1.111.118-2.495.118-3.615V0zm8.87 20.302c3.213 0 5.412-2.342 5.412-5.876 0-3.533-2.199-5.875-5.412-5.875-3.196 0-5.413 2.342-5.413 5.875 0 3.534 2.217 5.876 5.413 5.876z"
        fill={fillArray[2]}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69 6.743L58.309 28h-3.871l3.073-5.902-8.046-15.355h4.046l5.92 11.997 5.904-11.997H69z"
        fill={fillArray[3]}
      />
    </Svg>
  );
}

SvgLogo.defaultProps = {
  fill: null,
  width: 69
};

SvgLogo.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.number
};

export default React.memo(SvgLogo);
