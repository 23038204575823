import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { gStyle } from '../constants';

// screens
import MyEbayScreen from '../screens/MyEbay';

// components
import HeaderRight from '../components/HeaderRight';

// icons
import { SvgCart } from '../icons';

const Stack = createStackNavigator();

function StackMyEbay() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="MyEbay"
        component={MyEbayScreen}
        options={{
          title: null,
          headerRight: () => <HeaderRight icon={<SvgCart />} />,
          headerStyle: gStyle.navHeaderContainerStyle,
          headerTitleAlign: 'center',
          headerTitleStyle: gStyle.navHeaderTitleStyle
        }}
      />
    </Stack.Navigator>
  );
}

export default StackMyEbay;
