import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgCategories({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M17.5 11a4.498 4.498 0 01-3.182-7.682A4.5 4.5 0 0122 6.5a4.5 4.5 0 01-4.5 4.5zm1.768-6.268a2.5 2.5 0 10-3.535 3.536 2.5 2.5 0 003.535-3.536zM6.5 11a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-7a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM2.343 19.222a4.5 4.5 0 108.313-3.445 4.5 4.5 0 00-8.313 3.445zM4 17.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM17.5 22a4.498 4.498 0 01-3.182-7.682A4.5 4.5 0 0122 17.5a4.5 4.5 0 01-4.5 4.5zm0-7a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        fill={fill}
      />
    </Svg>
  );
}

SvgCategories.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgCategories.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgCategories);
