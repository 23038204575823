import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgChevronDown({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M12 17a.999.999 0 01-.71-.29l-8-8a1.003 1.003 0 111.42-1.42l7.29 7.3 7.29-7.3a1.004 1.004 0 011.42 1.42l-8 8A.999.999 0 0112 17z"
        fill={fill}
      />
    </Svg>
  );
}

SvgChevronDown.defaultProps = {
  fill: colors.n7,
  size: 24
};

SvgChevronDown.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgChevronDown);
