import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// icons
import { SvgArrowTopLeftSm } from '../icons';

function ItemAutocomplete({ keyword, onPress, text }) {
  const keywords = keyword.toLowerCase().trim().split(' ');
  const textArray = text.trim().split(' ');

  const regularWords = [];
  textArray.forEach((item, index) => {
    keywords.forEach((key) => {
      if (item.includes(key)) {
        regularWords.push(index);
      }
    });
  });

  return (
    <TouchableOpacity
      activeOpacity={gStyle.activeOpacity}
      onPress={onPress}
      style={styles.container}
    >
      <View style={styles.containerText}>
        <Text style={styles.text}>
          {textArray &&
            textArray.map((item, index) => {
              const isReg = regularWords.includes(index);
              const textExt = isReg ? { fontFamily: fonts.msRegular } : {};
              return <Text key={item} style={textExt}>{`${item} `}</Text>;
            })}
        </Text>
      </View>
      <SvgArrowTopLeftSm fill={colors.n3} />
    </TouchableOpacity>
  );
}

ItemAutocomplete.defaultProps = {
  onPress: () => null
};

ItemAutocomplete.propTypes = {
  // required
  keyword: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,

  // optional
  onPress: PropTypes.func
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.mB3,
    ...gStyle.pH1,
    alignItems: 'center',
    borderBottomColor: colors.n2,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  containerText: {
    flex: 1,
    justifyContent: 'center'
  },
  text: {
    fontFamily: fonts.msBold
  }
});

export default React.memo(ItemAutocomplete);
