import * as React from 'react';
import { StatusBar, View } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import { func } from './src/constants';

// root stack navigation
import RootStack from './src/navigation/RootStack';

// App State (Context)
import AppState from './src/context/AppState';

// keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    async function prepare() {
      try {
        if (__DEV__ === false) {
          func.checkOTAUpdate();
        }

        // pre-load/cache assets: images, fonts, and videos
        await func.loadAssetsAsync();
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setIsLoading(false);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = React.useCallback(async () => {
    if (isLoading === false) {
      // https://docs.expo.dev/versions/latest/sdk/splash-screen/
      // This tells the splash screen to hide immediately! If we call this after
      // `isLoading === false`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  return (
    <AppState>
      <StatusBar barStyle="dark-content" />

      <RootStack />

      <View onLayout={onLayoutRootView} />
    </AppState>
  );
}

export default App;
