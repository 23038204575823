import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgSearch({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M3 10a7 7 0 1114 0 7 7 0 01-14 0zm19.703 11.29l-5.67-5.67-.008-.003A8.954 8.954 0 0019 10a9 9 0 00-9-9 9 9 0 00-9 9 9 9 0 009 9 8.958 8.958 0 005.66-2.007l5.663 5.717c.19.187.445.292.71.29a1 1 0 00.67-1.71z"
        fill={fill}
      />
    </Svg>
  );
}

SvgSearch.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgSearch.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgSearch);
