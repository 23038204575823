import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Animated,
  Easing,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import * as Haptics from 'expo-haptics';
import { colors, device, fonts, func, gStyle } from '../constants';

// components
import MenuItemFilter from '../components/MenuItemFilter';
import MenuItemInput from '../components/MenuItemInput';
import MenuItemOptions from '../components/MenuItemOptions';
import MenuItemRadio from '../components/MenuItemRadio';
import MenuItemToggle from '../components/MenuItemToggle';

// icons
import { SvgChevronLeft, SvgClose } from '../icons';

// app state
import Context from '../context';

const { Light, Medium } = Haptics.ImpactFeedbackStyle;

const ANIMATE_DURATION = 300;

function DrawerFilterOld({ navigation }) {
  // app state
  const cntx = React.useContext(Context);
  const { locale, localeStrings } = cntx;
  const { radius, setRadius, showInstallListings, setInstallListings } = cntx;

  // translations
  const reset = localeStrings('reset', locale);
  const filter = localeStrings('filter', locale);

  const tireInstall = localeStrings('tireInstall', locale);
  const position = localeStrings('position', locale);
  const season = localeStrings('season', locale);
  const quantity = localeStrings('quantity', locale);
  const brand = localeStrings('brand', locale);
  const type = localeStrings('type', locale);
  const loadIndex = localeStrings('loadIndex', locale);
  const speedRating = localeStrings('speedRating', locale);
  const width = localeStrings('width', locale);
  const aspectRatio = localeStrings('aspectRatio', locale);
  const rimDiameter = localeStrings('rimDiameter', locale);
  const warranty = localeStrings('warranty', locale);
  const mileageWarranty = localeStrings('mileageWarranty', locale);
  const tireSizingSystem = localeStrings('tireSizingSystem', locale);
  const rebateAvailable = localeStrings('rebateAvailable', locale);
  const condition = localeStrings('condition', locale);
  const price = localeStrings('price', locale);
  const buyingFormat = localeStrings('buyingFormat', locale);
  const itemLocation = localeStrings('itemLocation', locale);
  const seller = localeStrings('seller', locale);
  const shippingOptions = localeStrings('shippingOptions', locale);
  const localPickup = localeStrings('localPickup', locale);
  const showMore = localeStrings('showMore', locale);
  const applyFilters = localeStrings('applyFilters', locale);

  const showWithInstall = localeStrings('showWithInstall', locale);
  const zipText = localeStrings('zip', locale);
  const availableWithin = localeStrings('availableWithin', locale);
  const howItWorks = localeStrings('howItWorks', locale);
  const how1H = localeStrings('how1H', locale);
  const how1P = localeStrings('how1P', locale);
  const how2H = localeStrings('how2H', locale);
  const how2P = localeStrings('how2P', locale);
  const how3H = localeStrings('how3H', locale);
  const how3P = localeStrings('how3P', locale);
  const how4H = localeStrings('how4H', locale);
  const how4P = localeStrings('how4P', locale);

  const isGerman = locale === 'de';
  const radiusFormat = isGerman ? func.milesToKm(radius) : `${radius} mi`;
  const miles25 = isGerman ? func.milesToKm(5) : '5 miles';
  const miles50 = isGerman ? func.milesToKm(10) : '10 miles';
  const miles100 = isGerman ? func.milesToKm(25) : '25 miles';
  const miles150 = isGerman ? func.milesToKm(50) : '50 miles';
  const miles250 = isGerman ? func.milesToKm(100) : '100 miles';

  const closeDrawer = () => {
    // on iOS device, use Haptic feedback
    if (device.iOS) Haptics.impactAsync(Medium);

    navigation.goBack();
  };

  const rightTireInstall = React.useRef(
    new Animated.Value(-device.width)
  ).current;

  const openTireInstall = () => {
    // on iOS device, use Haptic feedback
    if (device.iOS) Haptics.impactAsync(Light);

    Animated.timing(rightTireInstall, {
      toValue: 0,
      duration: ANIMATE_DURATION,
      easing: Easing.linear,
      useNativeDriver: false
    }).start();
  };

  const closeTireInstall = () => {
    // on iOS device, use Haptic feedback
    if (device.iOS) Haptics.impactAsync(Light);

    Animated.timing(rightTireInstall, {
      toValue: -device.width,
      duration: ANIMATE_DURATION,
      easing: Easing.linear,
      useNativeDriver: false
    }).start();
  };

  const rightRadius = React.useRef(new Animated.Value(-device.width)).current;

  const openRadiusScreen = () => {
    // on iOS device, use Haptic feedback
    if (device.iOS) Haptics.impactAsync(Light);

    Animated.timing(rightRadius, {
      toValue: 0,
      duration: ANIMATE_DURATION,
      easing: Easing.linear,
      useNativeDriver: false
    }).start();
  };

  const closeRadiusScreen = () => {
    // on iOS device, use Haptic feedback
    if (device.iOS) Haptics.impactAsync(Light);

    Animated.timing(rightRadius, {
      toValue: -device.width,
      duration: ANIMATE_DURATION,
      easing: Easing.linear,
      useNativeDriver: false
    }).start();
  };

  return (
    <View style={styles.container}>
      <View style={styles.containerNotch} />

      <View style={gStyle.flex1}>
        <View style={styles.header}>
          <TouchableOpacity
            activeOpacity={gStyle.activeOpacity}
            hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
            onPress={() => closeDrawer()}
            style={gStyle.headingIcon}
          >
            <SvgClose size={18} />
          </TouchableOpacity>

          <Text style={styles.headerTitle}>{filter}</Text>

          <TouchableOpacity
            activeOpacity={gStyle.activeOpacity}
            hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
            onPress={() => {
              closeDrawer();
            }}
          >
            <Text style={styles.textPrimary}>{reset}</Text>
          </TouchableOpacity>
        </View>

        <ScrollView showsVerticalScrollIndicator={false}>
          <MenuItemFilter onPress={openTireInstall} text={tireInstall} />
          <MenuItemOptions text={position} />
          <MenuItemFilter text={season} />
          <MenuItemFilter text={quantity} />
          <MenuItemFilter text={brand} />
          <MenuItemFilter text={type} />
          <MenuItemFilter text={loadIndex} />
          <MenuItemFilter text={speedRating} />
          <MenuItemFilter text={width} />
          <MenuItemFilter text={aspectRatio} />
          <MenuItemFilter text={rimDiameter} />
          <MenuItemFilter text={warranty} />
          <MenuItemFilter text={mileageWarranty} />
          <MenuItemFilter text={tireSizingSystem} />
          <MenuItemFilter text={rebateAvailable} />
          <MenuItemFilter text={condition} />
          <MenuItemFilter text={price} />
          <MenuItemFilter text={buyingFormat} />
          <MenuItemFilter text={itemLocation} />
          <MenuItemFilter text={seller} />
          <MenuItemFilter text={shippingOptions} />
          <MenuItemFilter text={localPickup} />

          <View style={styles.containerShowMore}>
            <Text style={styles.textPrimary}>{showMore}</Text>
          </View>

          <View style={styles.scrollSpacer} />
        </ScrollView>

        <Animated.View
          style={[styles.secondaryScreen, { right: rightTireInstall }]}
        >
          <View style={styles.header}>
            <TouchableOpacity
              activeOpacity={gStyle.activeOpacity}
              hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
              onPress={closeTireInstall}
              style={gStyle.headingIcon}
            >
              <SvgChevronLeft size={18} />
            </TouchableOpacity>

            <Text style={styles.headerTitle}>{tireInstall}</Text>

            <View style={styles.spacerIcon} />
          </View>

          <MenuItemToggle
            label={showWithInstall}
            onPress={() => {
              setInstallListings(!showInstallListings);
            }}
            selected={showInstallListings}
          />

          {showInstallListings && (
            <React.Fragment>
              <MenuItemInput text={zipText} />

              <MenuItemFilter
                onPress={openRadiusScreen}
                text={availableWithin}
                value={radiusFormat}
              />

              <View style={gStyle.spacer2} />

              <View style={styles.containerHowItWorks}>
                <Text style={styles.headingHowItWorks}>{howItWorks}</Text>
                <View style={gStyle.spacer2} />

                <Text style={styles.lineHowItWorks}>{how1H}</Text>
                <Text style={styles.paraHowItWorks}>{how1P}</Text>
                <View style={gStyle.spacer2} />

                <Text style={styles.lineHowItWorks}>{how2H}</Text>
                <Text style={styles.paraHowItWorks}>{how2P}</Text>
                <View style={gStyle.spacer2} />

                <Text style={styles.lineHowItWorks}>{how3H}</Text>
                <Text style={styles.paraHowItWorks}>{how3P}</Text>
                <View style={gStyle.spacer2} />

                <Text style={styles.lineHowItWorks}>{how4H}</Text>
                <Text style={styles.paraHowItWorks}>{how4P}</Text>
                <View style={gStyle.spacer2} />
              </View>
            </React.Fragment>
          )}
        </Animated.View>

        <Animated.View style={[styles.secondaryScreen, { right: rightRadius }]}>
          <View style={styles.header}>
            <TouchableOpacity
              activeOpacity={gStyle.activeOpacity}
              hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
              onPress={closeRadiusScreen}
              style={gStyle.headingIcon}
            >
              <SvgChevronLeft size={18} />
            </TouchableOpacity>

            <Text style={styles.headerTitle}>{availableWithin}</Text>

            <View style={styles.spacerIcon} />
          </View>

          <MenuItemRadio disabled text={miles25} />
          <MenuItemRadio disabled text={miles50} />
          <MenuItemRadio
            onPress={() => {
              // on iOS device, use Haptic feedback
              if (device.iOS) Haptics.impactAsync(Light);

              setRadius(25);
            }}
            selected={radius === 25}
            text={miles100}
          />
          <MenuItemRadio
            onPress={() => {
              // on iOS device, use Haptic feedback
              if (device.iOS) Haptics.impactAsync(Light);

              setRadius(50);
            }}
            selected={radius === 50}
            text={miles150}
          />
          <MenuItemRadio
            onPress={() => {
              // on iOS device, use Haptic feedback
              if (device.iOS) Haptics.impactAsync(Light);

              setRadius(100);
            }}
            selected={radius === 100}
            text={miles250}
          />
        </Animated.View>

        <View style={styles.containerSticky}>
          <TouchableOpacity
            activeOpacity={gStyle.activeOpacity}
            hitSlop={{ bottom: 10, left: 10, right: 10, top: 10 }}
            onPress={() => {
              closeDrawer();
            }}
            style={styles.button}
          >
            <Text style={styles.buttonText}>{applyFilters}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

DrawerFilterOld.propTypes = {
  // required
  navigation: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.n0,
    flex: 1
  },
  containerNotch: {
    backgroundColor: colors.n0,
    height: device.iPhoneNotch ? 46 : 16,
    width: '100%'
  },
  header: {
    ...gStyle.flexRowSpace,
    ...gStyle.pH2,
    ...gStyle.pB2,
    ...gStyle.pT1
  },
  headerTitle: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 18
  },
  textPrimary: {
    color: colors.b4,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  scrollSpacer: {
    height: 116,
    width: '100%'
  },
  spacerIcon: {
    height: 40,
    width: 40
  },
  containerShowMore: {
    ...gStyle.mT2,
    alignItems: 'center'
  },
  secondaryScreen: {
    backgroundColor: colors.n0,
    flex: 1,
    height: '100%',
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  containerHowItWorks: {
    ...gStyle.mH2,
    ...gStyle.p2,
    backgroundColor: colors.n1,
    borderRadius: 16
  },
  headingHowItWorks: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 14
  },
  lineHowItWorks: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    marginBottom: 4
  },
  paraHowItWorks: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12
  },
  containerSticky: {
    ...gStyle.pT1,
    alignItems: 'center',
    backgroundColor: colors.n0,
    borderTopColor: colors.n2,
    borderTopWidth: 1,
    bottom: 0,
    paddingBottom: device.iPhoneNotch ? 38 : 24,
    position: 'absolute',
    width: '100%'
  },
  button: {
    alignItems: 'center',
    backgroundColor: colors.b4,
    borderRadius: 24,
    height: 48,
    justifyContent: 'center',
    width: 286
  },
  buttonText: {
    color: colors.n0,
    fontFamily: fonts.msBold,
    fontSize: 16
  }
});

export default DrawerFilterOld;
