import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgHelp({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M13 17a1 1 0 10-2 0 1 1 0 002 0zM9.928 9.371C10.152 8.814 10.911 8 12 8c.738 0 1.312.29 1.648.65.337.36.404.737.305 1.034-.117.352-.513.692-1.182 1.07-.096.055-.223.123-.355.194-.2.108-.413.223-.55.305-.133.08-.307.194-.457.339-.13.126-.409.435-.409.908V14a1 1 0 102 0v-1.093c.087-.05.173-.096.274-.149.128-.068.278-.147.482-.263.656-.371 1.712-1.031 2.094-2.179.376-1.126-.008-2.249-.74-3.032C14.375 6.498 13.261 6 12 6 9.89 6 8.515 7.52 8.072 8.629a1 1 0 001.856.742z"
        fill={fill}
      />
      <Path
        d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm0-2C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
        fill={fill}
      />
    </Svg>
  );
}

SvgHelp.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgHelp.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgHelp);
