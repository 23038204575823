import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgChevronUpSm({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 16 16" width={size}>
      <Path
        d="M14 12a1 1 0 01-.71-.29L8 6.41l-5.29 5.3A1 1 0 011.3 10.3l6-6a1 1 0 011.41 0l6 6A1 1 0 0114 12z"
        fill={fill}
      />
    </Svg>
  );
}

SvgChevronUpSm.defaultProps = {
  fill: colors.n7,
  size: 16
};

SvgChevronUpSm.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgChevronUpSm);
