import * as React from 'react';
import { Animated, Easing, StyleSheet, View } from 'react-native';
import { colors, device } from '../constants';

function LoadingAnimation() {
  const loadingWidth = device.width - 32;
  const leftDuration = 1000;
  const scaleAnimation = 1000;

  // default and scale to values
  // scale is interchanged with other defaults to always keep same width of bar
  const redWDefault = Math.floor(loadingWidth * 0.36);
  const redWScale = Math.floor(loadingWidth * 0.22);

  const blueWDefault = Math.floor(loadingWidth * 0.13);
  const blueWScale = Math.floor(loadingWidth * 0.36);

  const yellowWDefault = Math.floor(loadingWidth * 0.22);
  const yellowWScale = Math.floor(loadingWidth * 0.29);

  const greenWDefault = Math.floor(loadingWidth * 0.29);
  const greenWScale = Math.floor(loadingWidth * 0.13);

  // initial ref values
  const left = React.useRef(new Animated.Value(0)).current;
  const redWidth = React.useRef(new Animated.Value(redWDefault)).current;
  const blueWidth = React.useRef(new Animated.Value(blueWDefault)).current;
  const yellowWidth = React.useRef(new Animated.Value(yellowWDefault)).current;
  const greenWidth = React.useRef(new Animated.Value(greenWDefault)).current;

  // animation shared obj
  const animationScaleShared = {
    duration: scaleAnimation,
    easing: Easing.linear,
    useNativeDriver: false
  };

  React.useEffect(() => {
    // mount
    Animated.loop(
      Animated.timing(left, {
        duration: leftDuration,
        easing: Easing.linear,
        toValue: loadingWidth + 16,
        useNativeDriver: false
      })
      // { iterations: 20 }
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(redWidth, {
          toValue: redWScale,
          ...animationScaleShared
        }),
        Animated.timing(redWidth, {
          toValue: redWDefault,
          ...animationScaleShared
        })
      ])
      // { iterations: 10 }
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(blueWidth, {
          toValue: blueWScale,
          ...animationScaleShared
        }),
        Animated.timing(blueWidth, {
          toValue: blueWDefault,
          ...animationScaleShared
        })
      ])
      // { iterations: 10 }
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(yellowWidth, {
          toValue: yellowWScale,
          ...animationScaleShared
        }),
        Animated.timing(yellowWidth, {
          toValue: yellowWDefault,
          ...animationScaleShared
        })
      ])
      // { iterations: 10 }
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.timing(greenWidth, {
          toValue: greenWScale,
          ...animationScaleShared
        }),
        Animated.timing(greenWidth, {
          toValue: greenWDefault,
          ...animationScaleShared
        })
      ]),
      { iterations: 10 }
    ).start();

    return () => {
      // unmount
    };
  }, []);

  return (
    <View style={styles.container}>
      <Animated.View style={[styles.animation, { left }]}>
        <Animated.View
          style={[styles.bar, styles.redBar, { width: redWidth }]}
        />
        <Animated.View
          style={[styles.bar, styles.blueBar, { width: blueWidth }]}
        />
        <Animated.View
          style={[styles.bar, styles.yellowBar, { width: yellowWidth }]}
        />
        <Animated.View
          style={[styles.bar, styles.greenBar, { width: greenWidth }]}
        />
        <Animated.View
          style={[styles.bar, styles.redBar, { width: redWidth }]}
        />
        <Animated.View
          style={[styles.bar, styles.blueBar, { width: blueWidth }]}
        />
        <Animated.View
          style={[styles.bar, styles.yellowBar, { width: yellowWidth }]}
        />
        <Animated.View
          style={[
            styles.bar,
            styles.greenBar,
            { marginRight: 0, width: greenWidth }
          ]}
        />
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 2,
    height: 6,
    overflow: 'hidden',
    width: '100%'
  },
  animation: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  bar: {
    borderRadius: 2,
    height: 6,
    marginRight: 4
  },
  redBar: {
    backgroundColor: colors.r4
  },
  blueBar: {
    backgroundColor: colors.b4
  },
  yellowBar: {
    backgroundColor: colors.y3
  },
  greenBar: {
    backgroundColor: colors.l4
  }
});

export default React.memo(LoadingAnimation);
