import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgNotification({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M12 0a3 3 0 00-3 3v.59A8 8 0 004 11v3.72l-2.86 4.77A1 1 0 002 21h7a3 3 0 006 0h7a1 1 0 00.86-1.51L20 14.72V11a8 8 0 00-5-7.41V3a3 3 0 00-3-3zM3.77 19l2.09-3.49A1 1 0 006 15v-4a6 6 0 0112 0v4a1 1 0 00.14.51L20.23 19H3.77zM11 3a1 1 0 112 0v.07a7.23 7.23 0 00-2 0V3zm0 18h2a1 1 0 11-2 0z"
        fill={fill}
      />
    </Svg>
  );
}

SvgNotification.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgNotification.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgNotification);
