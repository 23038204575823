import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { gStyle } from '../constants';

// screens
import SellingScreen from '../screens/Selling';

// components
import HeaderRight from '../components/HeaderRight';

// icons
import { SvgCart } from '../icons';

// app state
import Context from '../context';

const Stack = createStackNavigator();

function StackSelling() {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  // translations
  const sellingText = localeStrings('selling', locale);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Selling"
        component={SellingScreen}
        options={{
          title: sellingText,
          headerRight: () => <HeaderRight icon={<SvgCart />} />,
          headerStyle: gStyle.navHeaderContainerStyle,
          headerTitleAlign: 'center',
          headerTitleStyle: gStyle.navHeaderTitleStyle
        }}
      />
    </Stack.Navigator>
  );
}

export default StackSelling;
