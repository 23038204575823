import * as React from 'react';
import PropTypes from 'prop-types';
import { Pressable, StyleSheet, Text } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

function QuickFilter({ onPress, selected, styleExtend, text }) {
  const style = [styles.base, styleExtend, selected ? styles.baseSelected : {}];
  const pressedBg = selected ? '#c2d0fb' : colors.n2;
  const styleText = [styles.text, selected ? styles.textActive : {}];

  return (
    <Pressable
      activeOpacity={gStyle.activeOpacity}
      onPress={onPress}
      style={({ pressed }) => [
        style,
        pressed ? { backgroundColor: pressedBg } : {}
      ]}
    >
      <Text style={styleText}>{text}</Text>
    </Pressable>
  );
}

QuickFilter.defaultProps = {
  onPress: () => null,
  selected: false,
  styleExtend: {}
};

QuickFilter.propTypes = {
  // required
  text: PropTypes.string.isRequired,

  // optional
  onPress: PropTypes.func,
  selected: PropTypes.bool,
  styleExtend: PropTypes.oneOfType([PropTypes.number, PropTypes.object])
};

const styles = StyleSheet.create({
  base: {
    ...gStyle.pH2,
    alignItems: 'center',
    alignSelf: 'flex-start',
    backgroundColor: colors.n1,
    borderRadius: 16,
    height: 32,
    justifyContent: 'center'
  },
  baseSelected: {
    borderColor: colors.n7,
    borderWidth: 1
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12
  },
  textActive: {
    fontFamily: fonts.msBold
  }
});

export default React.memo(QuickFilter);
