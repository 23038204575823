import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgInfoFilled({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0zm-1 7a1 1 0 112 0 1 1 0 01-2 0zm1 3a1 1 0 011 1v6a1 1 0 11-2 0v-6a1 1 0 011-1z"
        fill={fill}
      />
    </Svg>
  );
}

SvgInfoFilled.defaultProps = {
  fill: colors.n7,
  size: 24
};

SvgInfoFilled.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgInfoFilled);
