import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgArrowRight({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M21.71 11.29l-8-8a1.004 1.004 0 00-1.42 1.42l6.3 6.29H3a1 1 0 000 2h15.59l-6.3 6.29a1.001 1.001 0 000 1.42 1.001 1.001 0 001.42 0l8-8a1.001 1.001 0 000-1.42z"
        fill={fill}
      />
    </Svg>
  );
}

SvgArrowRight.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgArrowRight.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgArrowRight);
