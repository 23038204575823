import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgMarkerBottom({ fill, width }) {
  // get height and keep aspect ratio
  const height = Math.ceil((6 / 13) * width);

  return (
    <Svg height={height} viewBox="0 0 13 6" width={width}>
      <Path
        fill={fill}
        fillRule="evenodd"
        d="m6.5 6-6-6h12l-6 6Z"
        clipRule="evenodd"
      />
    </Svg>
  );
}

SvgMarkerBottom.defaultProps = {
  fill: '#ffffff',
  width: 13
};

SvgMarkerBottom.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.number
};

export default React.memo(SvgMarkerBottom);
