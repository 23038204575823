import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';
import { colors } from '../constants';

function SvgSort({ fill, width }) {
  // get height and keep aspect ratio
  const height = Math.ceil((14 / 10) * width);

  return (
    <Svg height={height} viewBox="0 0 10 14" width={width}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.715 8.836a.775.775 0 01.51.182l3.795 3.316 3.796-3.316a.796.796 0 01.974.032.569.569 0 01.037.85l-4.305 3.754a.794.794 0 01-1.012 0L.205 9.9a.568.568 0 01-.15-.68c.112-.231.371-.383.66-.384zM5.49 1.018l4.305 3.754c.202.18.26.448.15.68-.112.232-.371.383-.66.384a.775.775 0 01-.51-.181L4.98 2.338 1.184 5.655a.796.796 0 01-.974-.033.569.569 0 01-.037-.85l4.305-3.754a.794.794 0 011.012 0z"
        fill={fill}
      />
    </Svg>
  );
}

SvgSort.defaultProps = {
  fill: colors.b4,
  width: 10
};

SvgSort.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.number
};

export default React.memo(SvgSort);
