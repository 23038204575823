import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// icons
import { SvgCheck } from '../icons';

// app state
import Context from '../context';

function CardFits() {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  // translations
  const thisFitsYourTireSize = localeStrings('thisFitsYourTireSize', locale);
  const readMore = localeStrings('readMore', locale);

  return (
    <View style={styles.container}>
      <View style={styles.containerContent}>
        <View style={styles.containerIcon}>
          <SvgCheck fill={colors.n0} size={20} />
        </View>

        <View style={styles.containerText}>
          <Text style={styles.textBold}>{thisFitsYourTireSize}</Text>

          <Text style={styles.text}>
            Size: 225/40/18 Load Index : 92 (1389...
          </Text>

          <View style={gStyle.spacer2} />

          <Text style={[styles.textBold, styles.textUnderline]}>
            {readMore}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    ...gStyle.pB2,
    ...gStyle.pH2,
    backgroundColor: colors.n1
  },
  containerContent: {
    ...gStyle.flexRow,
    ...gStyle.p2,
    backgroundColor: colors.n0,
    borderRadius: 8
  },
  containerIcon: {
    ...gStyle.mR2,
    alignItems: 'center',
    backgroundColor: colors.g6,
    borderRadius: 12,
    height: 24,
    justifyContent: 'center',
    width: 24
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  },
  textBold: {
    fontFamily: fonts.msBold
  },
  textUnderline: {
    textDecorationLine: 'underline'
  }
});

export default React.memo(CardFits);
