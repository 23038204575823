import * as React from 'react';
import PropTypes from 'prop-types';
import { Image, ScrollView, StyleSheet } from 'react-native';
import { colors, device, images } from '../constants';

function Email1({ route }) {
  const { image, height, width } = route.params;

  // get height and keep aspect ratio
  const newHeight = Math.ceil((height / width) * device.width);

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
      <Image
        source={images[image]}
        style={[styles.image, { height: newHeight }]}
      />
    </ScrollView>
  );
}

Email1.propTypes = {
  // required
  route: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  image: {
    backgroundColor: colors.n2,
    width: device.width
  }
});

export default Email1;
