import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgStarOutline({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M18.42 22.81A1 1 0 0019 23a1 1 0 001-1.24l-1.89-7.45 4.6-4.6A1 1 0 0022 8h-6.34l-2.74-6.39a1 1 0 00-1.84 0L8.34 8H2a.999.999 0 00-.74 1.71l4.6 4.6L4 21.76a1 1 0 001.58 1.05L12 18.23l6.42 4.58zm-5.84-6.62A1 1 0 0012 16a1 1 0 00-.58.19l-4.79 3.42 1.33-5.37a1.001 1.001 0 00-.26-.95L4.41 10H9c.401 0 .764-.24.92-.61L12 4.54l2.08 4.85A1 1 0 0015 10h4.59l-3.33 3.29c-.247.249-.346.61-.26.95l1.37 5.37-4.79-3.42z"
        fill={fill}
      />
    </Svg>
  );
}

SvgStarOutline.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgStarOutline.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgStarOutline);
