import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgSell({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M1 11.044a.992.992 0 00.287.709l10.952 10.95a.998.998 0 001.412-.002l9.053-9.052a.999.999 0 000-1.414L11.754 1.287A.992.992 0 0011.047 1l-6.003.025c-.266 0-.522.105-.711.293L1.318 4.33c-.188.19-.294.446-.293.712l-.025 6zm4.445-8.02l5.182-.022 9.963 9.952-7.624 7.623-9.963-9.961.02-5.171 2.422-2.421zM7.96 6.48a1.48 1.48 0 11-2.96 0 1.48 1.48 0 012.96 0z"
        fill={fill}
      />
    </Svg>
  );
}

SvgSell.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgSell.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgSell);
