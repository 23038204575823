import * as React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-native';
import { colors } from '../constants';

function SwitchToggle({ defaultValue, disabled, onSelect }) {
  const [isOn, onToggle] = React.useState(defaultValue);
  const onColor = disabled ? colors.b4 : colors.b4;
  const offColor = disabled ? colors.n3 : colors.n5;

  React.useEffect(() => {
    onToggle(defaultValue);
  }, [defaultValue]);

  return (
    <Switch
      activeThumbColor={colors.n0} // web only
      disabled={disabled}
      ios_backgroundColor={offColor}
      onValueChange={(value) => {
        // local component state
        onToggle(value);

        // pass back up the state
        onSelect(value);
      }}
      style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
      thumbColor={colors.n0}
      trackColor={{ false: offColor, true: onColor }}
      value={isOn}
    />
  );
}

SwitchToggle.defaultProps = {
  defaultValue: false,
  disabled: false,
  onSelect: () => null
};

SwitchToggle.propTypes = {
  // optional
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func
};

export default React.memo(SwitchToggle);
