import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, View } from 'react-native';
import { colors, fonts, func, gStyle } from '../constants';

function CardLocation(props) {
  const { address1, address2, distance } = props;
  const { nextAvailable, nextAvailableDay, price, selected, title } = props;

  // const todaysDate = func.getTodayDate();
  const todaysDate = '2023-04-18';
  const today = new Date(todaysDate);
  const nextAvil = new Date(todaysDate);
  nextAvil.setDate(today.getDate() + nextAvailableDay);
  const nextAvilDate = func.getDateString2(nextAvil);

  return (
    <View style={gStyle.flex1}>
      <View style={gStyle.flexRowSpace}>
        <Text style={styles.heading}>{title}</Text>
        <Text style={styles.price}>{`+ $${price}`}</Text>
      </View>

      <View style={gStyle.spacer1} />

      <View style={gStyle.flexRowSpace}>
        <View>
          <Text style={styles.address}>{address1}</Text>
          <Text style={styles.address}>{address2}</Text>
        </View>
        <Text style={styles.distance}>{`~${distance} mi away`}</Text>
      </View>

      {selected && (
        <React.Fragment>
          <View style={gStyle.spacer1} />
          <Text style={styles.hours}>Mon–Fri: 8am–5pm</Text>
          <Text style={styles.hours}>Sat–Sun: Closed</Text>
        </React.Fragment>
      )}

      <View style={gStyle.spacer2} />

      <Text style={styles.available}>Next available drop off:</Text>
      <Text style={styles.date}>{`${nextAvilDate} - ${nextAvailable}`}</Text>
    </View>
  );
}

CardLocation.propTypes = {
  // required
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
  nextAvailable: PropTypes.string.isRequired,
  nextAvailableDay: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

const styles = StyleSheet.create({
  heading: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 16,
    lineHeight: 20
  },
  price: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 14,
    lineHeight: 20
  },
  address: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  distance: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  hours: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  available: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  date: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 12,
    lineHeight: 16
  }
});

export default CardLocation;
