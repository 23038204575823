import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { gStyle } from '../constants';

// screens
import NotificationsScreen from '../screens/Notifications';

// components
import HeaderRight from '../components/HeaderRight';

// icons
import { SvgCart } from '../icons';

const Stack = createStackNavigator();

function StackNotifications() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          title: 'Notifications',
          headerRight: () => <HeaderRight icon={<SvgCart />} />,
          headerStyle: gStyle.navHeaderContainerStyle,
          headerTitleAlign: 'center',
          headerTitleStyle: gStyle.navHeaderTitleStyle
        }}
      />
    </Stack.Navigator>
  );
}

export default StackNotifications;
