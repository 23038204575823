import * as React from 'react';
import { Text, View } from 'react-native';
import { fonts, gStyle } from '../constants';

function Notifications() {
  return (
    <View style={[gStyle.containerBg, gStyle.flexCenter]}>
      <Text style={{ fontFamily: fonts.msRegular }}>Notifications Screen</Text>
    </View>
  );
}

export default Notifications;
