import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Animated,
  Keyboard,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from 'react-native';
import { api, colors, device, fonts, gStyle } from '../constants';

// components
import ItemAutocomplete from '../components/ItemAutocomplete';

// icons
import { SvgCameraSm, SvgCart } from '../icons';

const ANIMATE_DURATION = 300;

function Search({ navigation }) {
  const [text, setText] = React.useState('225/40R18 Tires');
  const [isFocused, setFocused] = React.useState(false);
  const [autocompleteArray, setResult] = React.useState(null);

  const width = React.useRef(new Animated.Value(86)).current;
  const percentage = width.interpolate({
    inputRange: [84, 86],
    outputRange: ['84%', '86%']
  });

  const getSuggestions = async () => {
    if (text.trim() !== '') {
      const suggestArray = await api.autoSuggest(text.trim());
      const suggestArrayObjs = suggestArray.map((item, index) => ({
        id: index,
        text: item
      }));

      setResult(suggestArrayObjs);
    } else {
      setResult(null);
    }
  };

  const onSubmit = (searchText) => {
    // console.log(text);
    // console.log(searchText);
    // console.log('===========');
  };

  React.useEffect(() => {
    const timer = setTimeout(() => getSuggestions(), 800);

    return () => {
      clearTimeout(timer);
    };
  }, [text]);

  return (
    <ScrollView
      keyboardShouldPersistTaps="always"
      scrollEnabled={false}
      style={gStyle.containerBg}
    >
      <View style={styles.notch} />

      <View style={gStyle.flexRow}>
        <Animated.View style={{ width: percentage }}>
          <TextInput
            // autoFocus
            onBlur={() => {
              setFocused(false);

              Animated.timing(width, {
                duration: ANIMATE_DURATION,
                toValue: 86,
                useNativeDriver: false
              }).start();
            }}
            onChangeText={setText}
            onFocus={() => {
              setFocused(true);

              Animated.timing(width, {
                duration: ANIMATE_DURATION,
                toValue: 84,
                useNativeDriver: false
              }).start();
            }}
            onSubmitEditing={({ nativeEvent: { text: searchText } }) => {
              onSubmit(searchText);
            }}
            placeholder="Search for anything"
            placeholderTextColor={colors.n7}
            selectionColor={colors.b4}
            style={styles.input}
            value={text}
          />

          <View style={styles.containerIcon}>
            <SvgCameraSm />
          </View>
        </Animated.View>

        {isFocused === false && (
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => null}
            style={gStyle.headingIcon}
          >
            <SvgCart size={18} />
          </TouchableOpacity>
        )}

        {isFocused === true && (
          <TouchableOpacity
            activeOpacity={gStyle.activeOpacity}
            onPress={() => {
              setText('');
              Keyboard.dismiss();
            }}
            style={gStyle.flexCenter}
          >
            <Text style={styles.cancel}>Cancel</Text>
          </TouchableOpacity>
        )}
      </View>

      {autocompleteArray && (
        <View style={styles.containerResults}>
          {autocompleteArray.map((item) => (
            <ItemAutocomplete
              key={item.id}
              keyword={text}
              onPress={() => {
                navigation.navigate('SearchResult', {
                  searchText: item.text
                });
              }}
              text={item.text}
            />
          ))}
        </View>
      )}
    </ScrollView>
  );
}

Search.propTypes = {
  // required
  navigation: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  notch: {
    height: device.iPhoneNotch ? 46 : 16,
    width: '100%'
  },
  input: {
    ...gStyle.mH2,
    ...gStyle.pL2,
    backgroundColor: colors.n1,
    borderRadius: 20,
    color: colors.n7,
    fontFamily: fonts.msRegular,
    height: 40
  },
  containerIcon: {
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    right: 32
  },
  cancel: {
    color: colors.b4
  },
  containerResults: {
    ...gStyle.mT4,
    ...gStyle.mH2
  }
});

export default Search;
