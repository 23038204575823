import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgThumbsUp({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M23.995 12.44a3.856 3.856 0 01-.779 2.53 2.92 2.92 0 00-.151.229 5.573 5.573 0 01-.037 4.19A4.046 4.046 0 0119.195 23h-4.749a10.492 10.492 0 01-4.16-.854L6.795 20.65V22a1 1 0 01-1 1h-3.88a.96.96 0 01-.96-.96V8.6a.96.96 0 01.96-.96h3.84a.96.96 0 01.96.96v.891a2.456 2.456 0 002.021-1.32 5.88 5.88 0 011.842-2.069c.696-.544.937-.762.937-1.342V1.88a.96.96 0 01.96-.96h.96a3.844 3.844 0 013.84 3.84v1.945c0 .723-.117 1.44-.345 2.126l-.243.729h4.428a2.883 2.883 0 012.88 2.88zm-21.12 8.64h1.92V9.56h-1.92v11.52zm18.24-5.76a2.354 2.354 0 01.534-1.458 2.02 2.02 0 00.426-1.422.962.962 0 00-.96-.96h-5.76a.958.958 0 01-.911-1.264l.664-1.993a4.775 4.775 0 00.247-1.518V4.76a1.922 1.922 0 00-1.92-1.92v1.92a3.41 3.41 0 01-1.676 2.855 4.104 4.104 0 00-1.305 1.414 4.435 4.435 0 01-3.739 2.393v7.105l4.327 1.855a8.6 8.6 0 003.404.698h4.749a2.121 2.121 0 001.92-1.92c0-.149.034-.296.101-.429a3.694 3.694 0 000-2.982.953.953 0 01-.101-.429z"
        fill={fill}
      />
    </Svg>
  );
}

SvgThumbsUp.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgThumbsUp.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgThumbsUp);
