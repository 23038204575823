import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgClose({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M13.41 12l7.3-7.29a1.004 1.004 0 00-1.42-1.42L12 10.59l-7.29-7.3a1.003 1.003 0 10-1.42 1.42l7.3 7.29-7.3 7.29a1.001 1.001 0 000 1.42 1.001 1.001 0 001.42 0l7.29-7.3 7.29 7.3a1.001 1.001 0 001.42 0 1.001 1.001 0 000-1.42L13.41 12z"
        fill={fill}
      />
    </Svg>
  );
}

SvgClose.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgClose.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgClose);
