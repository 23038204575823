import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native';
import { colors, fonts, gStyle, images } from '../constants';

// components
import Button from '../components/Button';
import CardItemCheckout from '../components/CardItemCheckout';
import Divider from '../components/Divider';
import MenuItemFilter from '../components/MenuItemFilter';

// app state
import Context from '../context';

// data
import tireShops from '../data/tireShops.json';

/*
import Alert from '../components/Alert';

<Alert text="For vehicle drop-off information, refer to the email from SimpleTire." />

<View style={gStyle.spacer2} />
*/

function Checkout({ route, navigation }) {
  // app state
  const { locationKey } = React.useContext(Context);
  const { item, itemId } = route.params;

  const selectedInstaller = tireShops[locationKey];

  let priceTotal = item.price;
  let items = 1;
  let addS = '';
  if (selectedInstaller) {
    priceTotal += selectedInstaller.price;
    items += 1;
    addS = 's';
  }
  const taxTotal = priceTotal * 0.0475;
  const orderTotal = priceTotal + taxTotal + 1;

  return (
    <React.Fragment>
      <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
        <View style={gStyle.spacer2} />

        <View style={gStyle.pH2}>
          <Text style={styles.heading}>Seller: discounttire</Text>

          <View style={gStyle.spacer2} />

          <CardItemCheckout item={item} />

          <View style={gStyle.spacer2} />

          <Divider />
        </View>

        <MenuItemFilter text="Message the seller" />

        {locationKey !== null && (
          <View style={gStyle.pH2}>
            <View style={gStyle.spacer2} />

            <Text
              style={styles.heading}
            >{`Installation service: ${selectedInstaller.title}`}</Text>

            <View style={gStyle.spacer3} />

            <View style={styles.containerInstall}>
              <View style={styles.containerImage}>
                <Image source={images.simpletire} style={styles.image} />
                <View style={styles.scrim} />
              </View>

              <View style={styles.installMiddle}>
                <Text style={styles.text}>
                  Tire Installation - 4 Tire(s) - 68058 - Passenger
                </Text>
                <View style={gStyle.spacer1} />
                <Text style={styles.textUnderline}>Remove</Text>
              </View>

              <Text
                style={styles.installPrice}
              >{`$${selectedInstaller.price}`}</Text>
            </View>

            <View style={gStyle.spacer2} />

            <Divider />

            <View style={gStyle.spacer2} />

            <View style={styles.containerShip}>
              <View style={styles.containerMuted}>
                <Text style={styles.textMuted}>Ship to</Text>
              </View>

              <View style={styles.shipMiddle}>
                <Text style={styles.textReg}>Pep Boys c/o Matthew Smith</Text>
                <Text style={styles.textReg}>4441 Fauntleroy Way SW</Text>
                <Text style={styles.textReg}>Seattle, WA 98126</Text>
                <Text style={styles.textReg}>United States</Text>

                <View style={gStyle.spacer1} />

                <TouchableOpacity
                  activeOpacity={gStyle.activeOpacity}
                  onPress={() =>
                    navigation.navigate('ModalChangeInstaller', {
                      item,
                      itemId
                    })
                  }
                >
                  <Text style={styles.textUnderline}>Change installer</Text>
                </TouchableOpacity>
              </View>
            </View>

            <View style={gStyle.spacer2} />

            <Divider />
          </View>
        )}

        <MenuItemFilter text="Pay with" value="Paypal" />
        <MenuItemFilter text="Gift cards, coupons, eBay Bucks" />

        <View style={gStyle.pH2}>
          <View style={gStyle.spacer2} />

          <View style={styles.totalLine}>
            <Text style={styles.muteTotal}>{`Item${addS} (${items})`}</Text>
            <Text style={styles.muteTotal}>{`$${priceTotal.toFixed(2)}`}</Text>
          </View>

          <View style={styles.totalLine}>
            <Text style={styles.muteTotal}>Tax</Text>
            <Text style={styles.muteTotal}>{`$${taxTotal.toFixed(2)}`}</Text>
          </View>

          <View style={styles.totalLine}>
            <Text style={styles.muteTotal}>Tire recycling fee</Text>
            <Text style={styles.muteTotal}>$1.00</Text>
          </View>

          <View style={gStyle.spacer2} />

          <Divider />

          <View style={gStyle.spacer2} />

          <View style={styles.totalLine}>
            <Text style={styles.text}>Order total</Text>
            <Text style={styles.text}>{`$${orderTotal.toFixed(2)}`}</Text>
          </View>

          <View style={gStyle.spacer1} />

          <Text style={styles.textLegal}>
            By placing your order, you agree to the following:
          </Text>
          <Text style={styles.textLegal}>
            eBay&apos;s <Text style={styles.textUnderline}>User Agreement</Text>
          </Text>
          <Text style={styles.textLegal}>
            eBay&apos;s <Text style={styles.textUnderline}>Privacy Notice</Text>
          </Text>

          <View style={gStyle.spacer2} />

          <Text style={styles.textLegal}>
            The state of Washington requires eBay to collect sales tax and
            applicable fees from buyers. eBay will pay the collected tax to the
            tax authority.
          </Text>
          <Text style={styles.textUnderline}>Learn more</Text>
        </View>

        <View style={gStyle.spacer3} />
      </ScrollView>

      <View style={styles.contanerButtons}>
        <Button
          onPress={() => navigation.navigate('CheckoutSuccess', { item })}
          text="Confirm and pay"
        />
        <View style={gStyle.spacer2} />
      </View>
    </React.Fragment>
  );
}

Checkout.propTypes = {
  // required
  route: PropTypes.object.isRequired,
  navigation: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flex1,
    backgroundColor: colors.n0
  },
  heading: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 16,
    lineHeight: 24
  },
  containerInstall: {
    flexDirection: 'row'
  },
  containerImage: {
    alignItems: 'center',
    height: 72,
    justifyContent: 'center',
    width: 72
  },
  image: {
    height: 12,
    width: 62
  },
  scrim: {
    backgroundColor: colors.imageOverlay,
    borderRadius: 12,
    height: 72,
    position: 'absolute',
    width: 72
  },
  installMiddle: {
    ...gStyle.mH2,
    flex: 1
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  textUnderline: {
    color: colors.b4,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16,
    textDecorationLine: 'underline'
  },
  installPrice: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 12,
    lineHeight: 16
  },
  containerShip: {
    flexDirection: 'row'
  },
  containerMuted: {
    ...gStyle.mR2,
    height: 72,
    width: 72
  },
  textMuted: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    lineHeight: 20
  },
  textReg: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14,
    lineHeight: 20
  },
  totalLine: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  muteTotal: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 16
  },
  textLegal: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 10,
    lineHeight: 14
  },
  contanerButtons: {
    ...gStyle.p2,
    backgroundColor: colors.n0
  }
});

export default Checkout;
