import * as React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { colors } from '../constants';

// stacks
import StackHome from './StackHome';
import StackSearch from './StackSearch';
import StackMyEbay from './StackMyEbay';
import StackNotifications from './StackNotifications';
import StackSelling from './StackSelling';

// icons
import {
  SvgHome,
  SvgNotification,
  SvgSearch,
  SvgSell,
  SvgUser
} from '../icons';

// app state
import Context from '../context';

const Tab = createBottomTabNavigator();

function TabNavigation() {
  // app state
  const { locale, localeStrings } = React.useContext(Context);

  // translations
  const homeText = localeStrings('home', locale);
  const myEbayText = localeStrings('myEbay', locale);
  const searchText = localeStrings('search', locale);
  const notificationsText = localeStrings('notifications', locale);
  const sellingText = localeStrings('selling', locale);

  return (
    <Tab.Navigator
      initialRouteName="StackSearch"
      screenOptions={({ route }) => ({
        headerShown: false,
        tabBarActiveTintColor: colors.b4,
        tabBarInactiveTintColor: colors.n5,
        tabBarIcon: ({ color }) => {
          let iconName = <SvgHome fill={color} />;

          if (route.name === 'StackMyEbay') {
            iconName = <SvgUser fill={color} />;
          } else if (route.name === 'StackSearch') {
            iconName = <SvgSearch fill={color} />;
          } else if (route.name === 'StackNotifications') {
            iconName = <SvgNotification fill={color} />;
          } else if (route.name === 'StackSelling') {
            iconName = <SvgSell fill={color} />;
          }

          return iconName;
        }
      })}
    >
      <Tab.Screen
        name="StackHome"
        component={StackHome}
        options={{ tabBarLabel: homeText }}
      />
      <Tab.Screen
        name="StackMyEbay"
        component={StackMyEbay}
        options={{ tabBarLabel: myEbayText }}
      />
      <Tab.Screen
        name="StackSearch"
        component={StackSearch}
        options={{ tabBarLabel: searchText }}
      />
      <Tab.Screen
        name="StackNotifications"
        component={StackNotifications}
        options={{ tabBarLabel: notificationsText }}
      />
      <Tab.Screen
        name="StackSelling"
        component={StackSelling}
        options={{ tabBarLabel: sellingText }}
      />
    </Tab.Navigator>
  );
}

export default TabNavigation;
