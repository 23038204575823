import * as React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { colors, fonts, gStyle } from '../constants';

// components
import SwitchToggle from './SwitchToggle';

function MenuItemToggle({ label, noDivider, onPress, selected }) {
  const borderBottomWidth = noDivider ? 0 : 1;

  return (
    <TouchableOpacity
      activeOpacity={gStyle.activeOpacity}
      onPress={onPress}
      style={[styles.container, { borderBottomWidth }]}
    >
      <Text style={styles.label}>{label}</Text>

      <SwitchToggle defaultValue={selected} onSelect={onPress} />
    </TouchableOpacity>
  );
}

MenuItemToggle.defaultProps = {
  noDivider: false,
  onPress: () => null,
  selected: false
};

MenuItemToggle.propTypes = {
  // required
  label: PropTypes.string.isRequired,

  // optional
  noDivider: PropTypes.bool,
  onPress: PropTypes.func,
  selected: PropTypes.bool
};

const styles = StyleSheet.create({
  container: {
    ...gStyle.flexRowAlignCenter,
    ...gStyle.mH2,
    ...gStyle.pV2,
    borderBottomColor: colors.n2,
    justifyContent: 'space-between'
  },
  label: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 14
  }
});

export default React.memo(MenuItemToggle);
