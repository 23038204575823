import * as React from 'react';
import PropTypes from 'prop-types';
import Svg, { Path } from 'react-native-svg';

function SvgCamera({ fill, size }) {
  return (
    <Svg height={size} viewBox="0 0 24 24" width={size}>
      <Path
        d="M24 6v15a1 1 0 01-1 1H1a.999.999 0 01-1-1V6a.998.998 0 011-1h5.279l.772-2.316A1 1 0 018 2h8a1 1 0 01.949.684L17.721 5H23a.999.999 0 011 1zM2 7v13h20V7h-5a1 1 0 01-.949-.684L15.279 4H8.721l-.772 2.316A1 1 0 017 7H2zm10 1a5 5 0 105 5 5.006 5.006 0 00-5-5zm0 8a3 3 0 110-6 3 3 0 010 6z"
        fill={fill}
      />
    </Svg>
  );
}

SvgCamera.defaultProps = {
  fill: '#000000',
  size: 24
};

SvgCamera.propTypes = {
  // optional
  fill: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  size: PropTypes.number
};

export default React.memo(SvgCamera);
