import * as React from 'react';
import PropTypes from 'prop-types';
import { Image, StyleSheet, Text, View } from 'react-native';
import { colors, fonts, func, gStyle } from '../constants';

// app state
import Context from '../context';

function CardItemCheckoutSuccess({ item }) {
  // app state
  const { deliveryRange, locale } = React.useContext(Context);

  const { image, price, title } = item;

  const isGerman = locale === 'de';
  const priceFormat = isGerman ? func.usdToEuro(price) : `$${price.toFixed(2)}`;

  return (
    <View style={styles.container}>
      <View style={styles.containerContent}>
        <View>
          <Image source={{ uri: image }} style={styles.image} />
          <View style={styles.scrim} />
        </View>

        <View style={gStyle.flex1}>
          <Text style={styles.textTitle} numberOfLines={3}>
            {title}
          </Text>

          <View style={gStyle.spacerHalf} />

          <Text style={styles.textMuted} numberOfLines={1}>
            Quantity 1
          </Text>
        </View>
      </View>

      <View style={gStyle.spacer1} />

      <View style={styles.containerContent}>
        <View style={styles.placeholder} />

        <View style={gStyle.flex1}>
          <Text style={styles.text}>Delivery</Text>
          <Text style={styles.text}>{`Est. delivery: ${deliveryRange}`}</Text>
          <Text style={styles.text}>FedEx Ground or FedEx Home Delivery</Text>
          <Text style={styles.textBold}>Free</Text>
        </View>
      </View>
    </View>
  );
}

CardItemCheckoutSuccess.propTypes = {
  // required
  item: PropTypes.object.isRequired
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.n0
  },
  containerContent: {
    ...gStyle.flexRow,
    borderRadius: 16,
    justifyContent: 'space-between',
    width: '100%'
  },
  image: {
    ...gStyle.mR2,
    backgroundColor: colors.n2,
    borderRadius: 8,
    height: 64,
    width: 64
  },
  scrim: {
    backgroundColor: colors.imageOverlay,
    borderRadius: 12,
    height: 64,
    position: 'absolute',
    width: 64
  },
  placeholder: {
    ...gStyle.mR2,
    height: 64,
    width: 64
  },
  textTitle: {
    ...gStyle.mR2,
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 18
  },
  text: {
    color: colors.n7,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 18
  },
  textBold: {
    color: colors.n7,
    fontFamily: fonts.msBold,
    fontSize: 12,
    lineHeight: 18
  },
  textMuted: {
    color: colors.n5,
    fontFamily: fonts.msRegular,
    fontSize: 12,
    lineHeight: 18
  }
});

export default React.memo(CardItemCheckoutSuccess);
