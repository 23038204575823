import * as React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { gStyle } from '../constants';

// components
import Button from '../components/Button';

function Emails({ navigation }) {
  return (
    <View style={gStyle.containerBg}>
      <View style={gStyle.spacer4} />

      <View style={gStyle.pH2}>
        <Button
          onPress={() =>
            navigation.navigate('Email1', {
              image: 'email1',
              height: 3250,
              width: 750
            })
          }
          text="Email 1"
        />

        <View style={gStyle.spacer2} />

        <Button
          onPress={() =>
            navigation.navigate('Email1', {
              image: 'email2',
              height: 3084,
              width: 750
            })
          }
          text="Email 2"
        />

        <View style={gStyle.spacer2} />

        <Button
          onPress={() =>
            navigation.navigate('Email1', {
              image: 'email3',
              height: 3600,
              width: 750
            })
          }
          text="Email 3"
        />

        <View style={gStyle.spacer2} />

        <Button
          onPress={() =>
            navigation.navigate('Email1', {
              image: 'email4',
              height: 3478,
              width: 750
            })
          }
          text="Email 4"
        />
      </View>
    </View>
  );
}

Emails.propTypes = {
  // required
  navigation: PropTypes.object.isRequired
};

export default Emails;
